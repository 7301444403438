import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetch, usePost } from '../../hooks';
import SchoolDetailsView from './SchoolDetailsView';

const SchoolDetailsContainer = () => {
    const { schoolId } = useParams();
    const navigate = useNavigate();
    const { data: schoolData, loading, error, fetchData } = useFetch('ADMIN_SCHOOL', [schoolId]);
    const { postData } = usePost();

    // Stan dla modali
    const [showEditModal, setShowEditModal] = useState(false);
    const [showTeachersModal, setShowTeachersModal] = useState(false);
    const [showStudentsModal, setShowStudentsModal] = useState(false);

    const handleEdit = () => setShowEditModal(true);
    const handleCloseEditModal = () => setShowEditModal(false);

    const handleShowTeachersModal = () => setShowTeachersModal(true);
    const handleCloseTeachersModal = () => setShowTeachersModal(false);

    const handleShowStudentsModal = () => setShowStudentsModal(true);
    const handleCloseStudentsModal = () => setShowStudentsModal(false);

    const handleShowDepartments = () => {
        navigate(`/admin/schools/${schoolId}/departments`);
    };

    const handleSubmit = async (formData) => {
        try {
            await postData('ADMIN_SCHOOL_UPDATE', formData, [schoolId], 'PUT');
            fetchData();
            handleCloseEditModal();
        } catch (error) {
            console.error('Failed to update school:', error);
        }
    };

    return (
        <SchoolDetailsView
            loading={loading}
            error={error}
            school={schoolData}
            onEdit={handleEdit}
            showEditModal={showEditModal}
            handleCloseEditModal={handleCloseEditModal}
            handleSubmit={handleSubmit}
            handleShowDepartments={handleShowDepartments}
            showTeachersModal={showTeachersModal}
            handleShowTeachersModal={handleShowTeachersModal}
            handleCloseTeachersModal={handleCloseTeachersModal}
            showStudentsModal={showStudentsModal}
            handleShowStudentsModal={handleShowStudentsModal}
            handleCloseStudentsModal={handleCloseStudentsModal}
        />
    );
};

export default SchoolDetailsContainer;
