import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import worldManager from './initWorldManager';

const CanvasContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PythonCanvas = () => {
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [sprites, setSprites] = useState([]);

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        };

        updateDimensions();
        const resizeObserver = new ResizeObserver(updateDimensions);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        worldManager.setCanvasUpdate(setSprites);
        //console.log('Canvas connected to WorldManager');

        return () => {
            resizeObserver.disconnect();
            worldManager.setCanvasUpdate(null);
        };
    }, []);

    const size = Math.min(dimensions.width, dimensions.height);
    const viewBoxSize = 201;

    const getSpriteValue = (sprite, key) => {
        if (sprite instanceof Map) {
            return sprite.get(key);
        }
        return sprite[key];
    };

    const calculateTrianglePoints = (centerX, centerY, size) => {
        const halfSize = size / 2;
        const height = size * Math.sqrt(3) / 2;
        const top = `${centerX},${centerY - height/2}`;
        const bottomLeft = `${centerX - halfSize},${centerY + height/2}`;
        const bottomRight = `${centerX + halfSize},${centerY + height/2}`;
        return `${top} ${bottomLeft} ${bottomRight}`;
    };

    const renderSprite = (sprite) => {
        const x = parseFloat(getSpriteValue(sprite, 'x'));
        const y = parseFloat(getSpriteValue(sprite, 'y'));
        const size = parseFloat(getSpriteValue(sprite, 'size')) || 50;
        const color = getSpriteValue(sprite, 'color') || 'rgb(255,0,0)';
        const image = parseInt(getSpriteValue(sprite, 'image')) || 0;
        const direction = parseFloat(getSpriteValue(sprite, 'direction')) || 0;
        const id = getSpriteValue(sprite, 'id');

        const centerX = 100.5 + x;
        const centerY = 100.5 - y;

        switch (image) {
            case 1: // koło
                return (
                    <circle
                        key={id}
                        cx={centerX}
                        cy={centerY}
                        r={size/2}
                        fill={color}
                        transform={`rotate(${-direction} ${centerX} ${centerY})`}
                    />
                );
            case 2: // trójkąt
                const points = calculateTrianglePoints(centerX, centerY, size);
                return (
                    <polygon
                        key={id}
                        points={points}
                        fill={color}
                        transform={`rotate(${-direction} ${centerX} ${centerY})`}
                    />
                );
            default: // kwadrat
                return (
                    <rect
                        key={id}
                        x={centerX - size/2}
                        y={centerY - size/2}
                        width={size}
                        height={size}
                        fill={color}
                        transform={`rotate(${-direction} ${centerX} ${centerY})`}
                    />
                );
        }
    };

    return (
        <CanvasContainer ref={containerRef}>
            <svg
                width={size}
                height={size}
                viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
                style={{
                    backgroundColor: 'white',
                    border: '1px solid var(--bs-border-color)',
                    borderRadius: '4px',
                }}
            >
                <g stroke="rgba(0,0,0,0.1)" strokeWidth="0.5">
                    {Array.from({ length: 21 }, (_, i) => (
                        <React.Fragment key={`grid-${i}`}>
                            <line x1="0" y1={i * 10} x2={viewBoxSize} y2={i * 10} />
                            <line x1={i * 10} y1="0" x2={i * 10} y2={viewBoxSize} />
                        </React.Fragment>
                    ))}
                </g>

                <g stroke="rgba(0,0,0,0.3)" strokeWidth="1">
                    <line x1="100.5" y1="0" x2="100.5" y2={viewBoxSize} strokeDasharray="2,2" />
                    <line x1="0" y1="100.5" x2={viewBoxSize} y2="100.5" strokeDasharray="2,2" />
                </g>

                <circle cx="100.5" cy="100.5" r="2" fill="rgba(0,0,0,0.5)" />

                {sprites.map((sprite) => renderSprite(sprite))}
            </svg>
        </CanvasContainer>
    );
};

export default PythonCanvas;
