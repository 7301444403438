import React from 'react';
import CourseTable from '../components/CourseTable';
import ParticipantsModal from '../components/ParticipantsModal';
import CourseInstanceForm from '../components/CourseInstanceForm';
import CourseStatsModal from './CourseStatsModal';

const TeacherCoursesView = ({
                                courses,
                                loading,
                                error,
                                darkMode,
                                actions,
                                showParticipants,
                                selectedCourseId,
                                students,
                                selectedStudents,
                                studentsLoading,
                                studentsError,
                                onCloseParticipants,
                                onStudentSelect,
                                onSelectAll,
                                onSaveParticipants,
                                showInstanceForm,
                                instanceFormData,
                                onCloseInstanceForm,
                                onInstanceFormChange,
                                onInstanceFormSubmit,
                                showStats,
                                onCloseStats
                            }) => {
    const teacherCourses = courses?.filter(course => course.role_access.is_teacher) || [];

    return (
        <div className="container mt-4">
            <CourseTable
                courses={teacherCourses}
                loading={loading}
                error={error}
                darkMode={darkMode}
                userRole="teacher"
                actions={actions}
                userCourses={teacherCourses.map(course => course.id)}
            />

            <ParticipantsModal
                show={showParticipants}
                handleClose={onCloseParticipants}
                loading={studentsLoading}
                error={studentsError}
                students={students}
                selectedStudents={selectedStudents}
                onStudentSelect={onStudentSelect}
                onSelectAll={onSelectAll}
                onSave={onSaveParticipants}
                darkMode={darkMode}
            />

            <CourseInstanceForm
                show={showInstanceForm}
                handleClose={onCloseInstanceForm}
                formData={instanceFormData}
                handleChange={onInstanceFormChange}
                handleSubmit={onInstanceFormSubmit}
                error={error}
                loading={loading}
                darkMode={darkMode}
                courseName={courses?.find(c => c.id === selectedCourseId)?.course?.name}
            />

            <CourseStatsModal
                show={showStats}
                handleClose={onCloseStats}
                courseId={selectedCourseId}
                darkMode={darkMode}
            />
        </div>
    );
};

export default TeacherCoursesView;
