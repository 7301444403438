import React from 'react';
import { Card, Table, Button, Spinner, Alert, Form, Badge, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../context/ThemeContext';

const ExamsView = ({
                       loading,
                       error,
                       exams,
                       searchQuery,
                       onSearchChange,
                       filterType,
                       onFilterChange,
                       onAddExam,
                       onEditExam,
                       onManageStudents,
                       onViewResults,
                       isTeacher = false,
                       renderCustomActions,
                       onViewTasks
                   }) => {
    const { t } = useTranslation();
    const { darkMode } = useTheme();

    if (loading) {
        return <Spinner animation="border" className="d-block mx-auto mt-4" />;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    const getStatusInfo = (exam) => {
        const now = new Date();
        const startDate = new Date(exam.start_date);
        const endDate = new Date(exam.end_date);

        if (!exam.is_active) return { type: 'inactive', variant: 'secondary' };
        if (startDate > now) return { type: 'upcoming', variant: 'warning' };
        if (endDate < now) return { type: 'past', variant: 'danger' };
        return { type: 'current', variant: 'success' };
    };

    const getRowClassName = (exam) => {
        if (filterType === 'all') {
            const status = getStatusInfo(exam);
            return `table-${status.variant}`;
        }
        return '';
    };

    return (
        <div className="container mt-4">
            <Card className={darkMode ? 'bg-dark text-light' : ''}>
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5>{isTeacher ? t('examManagement') : t('myExams')}</h5>
                        {isTeacher && (
                            <Button
                                variant={darkMode ? 'outline-light' : 'primary'}
                                onClick={onAddExam}
                            >
                                {t('addExam')}
                            </Button>
                        )}
                    </div>
                    <div className="d-flex gap-3 mb-3">
                        <InputGroup>
                            <Form.Control
                                placeholder={t('searchExams')}
                                value={searchQuery}
                                onChange={(e) => onSearchChange(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                    <div className="d-flex gap-2">
                        <Button
                            variant={filterType === 'all' ? 'primary' : 'outline-primary'}
                            onClick={() => onFilterChange('all')}
                        >
                            {t('all')}
                        </Button>
                        <Button
                            variant={filterType === 'current' ? 'success' : 'outline-success'}
                            onClick={() => onFilterChange('current')}
                        >
                            {t('current')}
                        </Button>
                        <Button
                            variant={filterType === 'upcoming' ? 'warning' : 'outline-warning'}
                            onClick={() => onFilterChange('upcoming')}
                        >
                            {t('upcoming')}
                        </Button>
                        <Button
                            variant={filterType === 'past' ? 'danger' : 'outline-danger'}
                            onClick={() => onFilterChange('past')}
                        >
                            {t('past')}
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div style={{ height: 'calc(100vh - 350px)', overflow: 'auto' }}>
                        <Table responsive bordered className={darkMode ? 'table-dark' : ''}>
                            <thead style={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: darkMode ? 'var(--bs-dark)' : 'var(--bs-table-bg)',
                                zIndex: 1
                            }}>
                            <tr>
                                <th>{t('examName')}</th>
                                <th>{t('status')}</th>
                                <th>{t('dates')}</th>
                                <th>{t('settings')}</th>
                                {isTeacher && (
                                    <>
                                        <th>{t('students')}</th>
                                        <th>{t('attempts')}</th>
                                    </>
                                )}
                                <th>{t('actions')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {exams.map(exam => {
                                const status = getStatusInfo(exam);
                                return (
                                    <tr key={exam.id} className={getRowClassName(exam)}>
                                        <td>{exam.topic_name || exam.name}</td>
                                        <td>
                                            <Badge bg={status.variant}>
                                                {t(status.type)}
                                            </Badge>
                                        </td>
                                        <td>
                                            <div>{new Date(exam.start_date).toLocaleString()}</div>
                                            <div>{new Date(exam.end_date).toLocaleString()}</div>
                                        </td>
                                        <td>
                                            <div>{exam.time_limit ? `${t('timeLimit')}: ${exam.time_limit} min` : t('noTimeLimit')}</div>
                                            <div>{t('canSeeResults')}: {exam.can_see_results ? t('yes') : t('no')}</div>
                                        </td>
                                        {isTeacher && (
                                            <>
                                                <td>
                                                    <Badge bg="info">
                                                        {exam.students_count} {t('students')}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <div>{t('ongoing')}: {exam.ongoing_attempts}</div>
                                                    <div>{t('completed')}: {exam.completed_attempts}</div>
                                                </td>
                                            </>
                                        )}
                                        <td>
                                            <div className="d-flex gap-2">
                                                {renderCustomActions?.(exam)}
                                                {isTeacher && (
                                                    <>
                                                        <Button
                                                            variant="primary"
                                                            size="sm"
                                                            onClick={() => onViewTasks(exam)}
                                                        >
                                                            {t('viewTasks')}
                                                        </Button>
                                                        <Button
                                                            variant="secondary"
                                                            size="sm"
                                                            onClick={() => onEditExam(exam)}
                                                        >
                                                            {t('edit')}
                                                        </Button>
                                                        <Button
                                                            variant="info"
                                                            size="sm"
                                                            onClick={() => onManageStudents(exam)}
                                                        >
                                                            {t('manageStudents')}
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ExamsView;
