import React from 'react';
import { Modal, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AdminEntityFormModal = ({
                                  show,
                                  handleClose,
                                  formData,
                                  handleChange,
                                  handleSubmit,
                                  error,
                                  loading,
                                  isEdit,
                                  title,
                                  fields,
                                  columns = 1,
                              }) => {
    const { t } = useTranslation();

    const handleFieldChange = (field, event) => {
        let value;

        if (field.type === 'multiselect') {
            value = Array.from(event.target.selectedOptions, option => option.value);
        } else if (field.type === 'checkbox') {
            value = event.target.checked;
        } else {
            value = event.target.value;
        }

        if (field.onChange) {
            field.onChange(value);
        }

        handleChange({
            target: {
                name: field.name,
                value: value
            }
        });
    };

    const renderField = (field) => {
        if (field.hideOnEdit && isEdit) return null;

        if (field.type === 'select') {
            return (
                <Form.Group className="mb-3">
                    <Form.Label>{t(field.label)}</Form.Label>
                    <Form.Control
                        as="select"
                        name={field.name}
                        value={formData[field.name] || ''}
                        onChange={(e) => handleFieldChange(field, e)}
                        required={field.required}
                        disabled={field.disabled}
                    >
                        <option value="">{t(field.placeholder || 'select')}</option>
                        {field.options?.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            );
        }

        if (field.type === 'checkboxGroup') {
            return (
                <Form.Group className="mb-3">
                    <Form.Label>{t(field.label)}</Form.Label>
                    <div className="d-flex flex-column gap-2">
                        {field.options?.map(option => (
                            <Form.Check
                                key={option.id}
                                type="checkbox"
                                id={`${field.name}-${option.id}`}
                                label={option.name}
                                checked={formData[field.name]?.includes(option.id.toString())}
                                onChange={(e) => {
                                    const currentValues = formData[field.name] || [];
                                    const newValues = e.target.checked
                                        ? [...currentValues, option.id.toString()]
                                        : currentValues.filter(id => id !== option.id.toString());

                                    handleChange({
                                        target: {
                                            name: field.name,
                                            value: newValues
                                        }
                                    });
                                }}
                                disabled={field.disabled}
                            />
                        ))}
                    </div>
                </Form.Group>
            );
        }

        if (field.type === 'checkbox') {
            return (
                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label={t(field.label)}
                        name={field.name}
                        checked={formData[field.name] || false}
                        onChange={(e) => handleFieldChange(field, e)}
                        disabled={field.disabled}
                    />
                </Form.Group>
            );
        }

        return (
            <Form.Group className="mb-3">
                <Form.Label>{t(field.label)}</Form.Label>
                <Form.Control
                    type={field.type}
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={(e) => handleFieldChange(field, e)}
                    required={field.required}
                    disabled={field.disabled}
                />
            </Form.Group>
        );
    };

    const renderFields = () => {
        if (columns === 2) {
            return (
                <Row>
                    {fields.map(field => (
                        <Col md={6} key={field.name}>
                            {renderField(field)}
                        </Col>
                    ))}
                </Row>
            );
        }

        return fields.map(field => renderField(field));
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size={columns === 2 ? 'lg' : undefined}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {isEdit ? t(`edit${title}`) : t(`add${title}`)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {error && <Alert variant="danger">{t(error)}</Alert>}
                    {renderFields()}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('cancel')}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {isEdit ? t(`update${title}`) : t(`add${title}`)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AdminEntityFormModal;
