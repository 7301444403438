import { BrowserRunner } from './BrowserRunner';
import { ServerRunner } from './ServerRunner';
import { PythonBrowserRunner } from './PythonBrowserRunner';
//import { CppBrowserRunner } from './CppBrowserRunner';
//import { JavaWasmRunner } from './JavaWasmRunner';

export class RunnerFactory {
    static createRunner(language, options = {}) {
        switch (language?.toLowerCase()) {
            case 'javascript':
            case 'html':
                return new BrowserRunner(options.darkMode);
            //case 'java':
            //    return new JavaWasmRunner(options.darkMode);
            case 'python':
                return new PythonBrowserRunner(options.darkMode);
            //case 'cpp':
            //case 'c++':
            //    return new CppBrowserRunner(options.darkMode);
            default:
                return new ServerRunner(options.apiEndpoint);
        }
    }
}
