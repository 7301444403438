import React, { useState } from 'react';
import TasksLayout from '../components/TasksLayout';
import StudentTasksList from './StudentTasksList';
import StudentTaskContent from './StudentTaskContent';

const StudentTasksView = ({
                              tasksList,
                              selectedTaskId,
                              setSelectedTaskId,
                              taskDetails,
                              taskLoading,
                              taskError,
                              theme,
                              setTheme,
                              editorContent,
                              setEditorContent,
                              outputMode,
                              setOutputMode,
                              codeOutput,
                              onRunCode,
                              onResetCode,
                              runCounter,
                              showConsole,
                              setShowConsole,
                              consoleLogCount,
                              consoleOutput,
                              onConsoleMessage,
                              setConsoleLogCount,
                              isExam,
                              examStartTime,
                              examTimeLimit,
                              onExamTimeEnd,
                              onExamEnd,
                              examId,
                              attemptId,
                          }) => {
    const [fontSize, setFontSize] = useState(15);

    const navbarProps = {
        theme,
        setTheme,
        onRunCode,
        outputMode,
        setOutputMode,
        onResetCode: (!isExam && taskDetails?.type === 'task') ? onResetCode : undefined,
        fontSize,
        onFontSizeChange: setFontSize,
        taskDetails,
        showConsole,
        setShowConsole,
        consoleLogCount,
        taskId: selectedTaskId,
        editorContent,
        setEditorContent,
        isExam,
        examId,
        attemptId
    };

    const editorProps = {
        theme,
        editorContent,
        setEditorContent,
        taskId: selectedTaskId,
        isExample: taskDetails?.type === 'example',
        language: taskDetails?.language,
        fontSize,
        readOnly: taskDetails?.type === 'example',
        taskType: taskDetails?.type,
        isExam
    };

    const outputProps = {
        output: codeOutput,
        outputMode,
        runCounter,
        taskId: selectedTaskId,
        showConsole,
        consoleOutput,
        onConsoleMessage,
        setConsoleLogCount
    };

    return (
        <TasksLayout
            LeftSidebar={
                <StudentTasksList
                    tasksList={tasksList}
                    selectedTaskId={selectedTaskId}
                    onSelect={setSelectedTaskId}
                />
            }
            RightSidebar={
                <StudentTaskContent
                    loading={taskLoading}
                    error={taskError}
                    task={taskDetails}
                    isExam={isExam}
                    examStartTime={examStartTime}
                    examTimeLimit={examTimeLimit}
                    onExamTimeEnd={onExamTimeEnd}
                    onExamEnd={onExamEnd}
                />
            }
            navbarProps={navbarProps}
            editorProps={editorProps}
            outputProps={outputProps}
            error={taskError}
        />
    );
};

export default StudentTasksView;
