import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const CourseActions = ({
                           course,
                           index,
                           totalCourses,
                           userRole,
                           actions,
                           userCourses,
                           darkMode
                       }) => {
    const { t } = useTranslation();

    const isUserInCourse = userCourses.includes(course.id);

    const basicActions = (
        <Button
            size="sm"
            variant={darkMode ? "outline-info" : "info"}
            onClick={() => actions.onViewTopics?.(course.id)}
        >
            {t('topics')}
        </Button>
    );

    const teacherActions = (
        <>
            {(userRole === 'admin' || (userRole === 'teacher' && isUserInCourse)) && (
                <Button
                    size="sm"
                    variant={darkMode ? "outline-light" : "secondary"}
                    onClick={() => actions.onEdit?.(course)}
                >
                    {t('edit')}
                </Button>
            )}
            {(userRole === 'teacher' && isUserInCourse) && (
                <>
                    <Button
                        size="sm"
                        variant={darkMode ? "outline-success" : "success"}
                        onClick={() => actions.onManageParticipants?.(course.id)}
                    >
                        {t('participants')}
                    </Button>
                    <Button
                        size="sm"
                        variant={darkMode ? "outline-warning" : "warning"}
                        onClick={() => actions.onShowStats?.(course.id)}
                    >
                        {t('statistics')}
                    </Button>
                </>
            )}
        </>
    );

    const adminActions = userRole === 'admin' && (
        <>
            <Button
                size="sm"
                variant={darkMode ? "outline-light" : "outline-primary"}
                onClick={() => actions.onMoveUp?.(course.id)}
                disabled={index === 0}
                className={darkMode && index === 0 ? "opacity-50" : ""}
            >
                ↑
            </Button>
            <Button
                size="sm"
                variant={darkMode ? "outline-light" : "outline-primary"}
                onClick={() => actions.onMoveDown?.(course.id)}
                disabled={index === totalCourses - 1}
                className={darkMode && index === totalCourses - 1 ? "opacity-50" : ""}
            >
                ↓
            </Button>
            <Button
                size="sm"
                variant={darkMode ? "outline-danger" : "danger"}
                onClick={() => actions.onDelete?.(course.id)}
            >
                {t('delete')}
            </Button>
        </>
    );

    return (
        <div className="d-flex gap-2">
            {basicActions}
            {teacherActions}
            {adminActions}
        </div>
    );
};

export default CourseActions;
