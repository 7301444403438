import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const StyledDropdownItem = styled(Dropdown.Item)`
    &.not-started {
        color: ${props => props.theme.danger || '#dc3545'};
    }

    &.in-progress {
        color: ${props => props.theme.warning || '#ffc107'};
    }

    &.completed {
        color: ${props => props.theme.success || '#28a745'};
    }
`;

const CourseStudentDropdown = ({ students, selectedStudent, onStudentSelect, isExam = false }) => {
    // Teraz zawsze mamy obiekt z podziałem na grupy
    const currentStudents = students?.students || [];
    const availableStudents = students?.available_students || [];

    if ((!currentStudents || currentStudents.length === 0) && !availableStudents.length) return null;

    const getStudentStatusClass = (student) => {
        if (!student.has_started) return 'not-started';
        if (isExam) {
            // Dla egzaminu sprawdzamy dodatkowe statusy
            if (student.attempts_used > 0) return 'completed';
            return 'in-progress';
        }
        // Dla zwykłego zadania
        return 'completed';
    };

    const getStudentLabel = (student) => {
        let label = `${student.name} ${student.surname || ''}`.trim();
        if (isExam && student.attempts_used > 0) {
            label += ` (${student.attempts_used} podejść)`;
        }
        if (!isExam && student.has_started) {
            label += ' (✓)';
        }
        return label;
    };

    return (
        <Dropdown className="me-2">
            <Dropdown.Toggle variant={selectedStudent ? "primary" : "light"}>
                {selectedStudent ? getStudentLabel(selectedStudent) : 'Lista studentów'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {selectedStudent && (
                    <>
                        <Dropdown.Item onClick={() => onStudentSelect(null)}>
                            {isExam ? 'Powrót do zadania' : 'Powrót do swojego kodu'}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                    </>
                )}

                {currentStudents.length > 0 && (
                    <>
                        {isExam && <Dropdown.Header>Studenci z dostępem do egzaminu</Dropdown.Header>}
                        {currentStudents.map(student => (
                            <StyledDropdownItem
                                key={student.id}
                                onClick={() => onStudentSelect(student)}
                                active={selectedStudent?.id === student.id}
                                className={getStudentStatusClass(student)}
                            >
                                {getStudentLabel(student)}
                            </StyledDropdownItem>
                        ))}
                    </>
                )}

                {availableStudents.length > 0 && (
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Header>Studenci bez dostępu</Dropdown.Header>
                        {availableStudents.map(student => (
                            <StyledDropdownItem
                                key={student.id}
                                className="not-started"
                                disabled
                            >
                                {getStudentLabel(student)}
                            </StyledDropdownItem>
                        ))}
                    </>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default CourseStudentDropdown;
