export const schoolFormConfig = {
    title: "School",
    columns: 2,
    fields: [
        // Podstawowe informacje
        { name: 'name', type: 'text', label: 'schoolName', required: true },
        { name: 'status', type: 'select', label: 'status', required: true,
            options: [
                { id: 'ACTIVE', name: 'Active' },
                { id: 'INACTIVE', name: 'Inactive' }
            ]
        },
        { name: 'code', type: 'text', label: 'schoolCode', required: true },
        { name: 'school_year', type: 'number', label: 'schoolYear', required: true },

        // Dane kontaktowe i adresowe
        { name: 'email', type: 'email', label: 'email' },
        { name: 'phone', type: 'text', label: 'phone' },
        { name: 'address', type: 'text', label: 'schoolAddress' },
        { name: 'capacity', type: 'number', label: 'capacity' },

        // Dodatkowe informacje
        { name: 'description', type: 'text', label: 'schoolDescription' }
    ],
    defaultValues: {
        name: '',
        code: '',
        description: '',
        address: '',
        school_year: new Date().getFullYear(),
        capacity: '',
        phone: '',
        email: '',
        status: 'ACTIVE'
    }
};

export const userFormConfig = {
    // konfiguracja dla formularza użytkownika
};

export const departmentFormConfig = {
    // konfiguracja dla formularza departamentu
};
