import React from 'react';
import { useNavigate } from 'react-router-dom';
import SchoolsListContainer from './SchoolsListContainer';

const SchoolsContainer = () => {
    const navigate = useNavigate();

    const handleSchoolClick = (school) => {
        navigate(`/admin/schools/${school.id}`);
    };

    const viewConfig = {
        title: 'allSchools',
        hideColumns: []
    };

    return (
        <SchoolsListContainer
            viewConfig={viewConfig}
            onSchoolClick={handleSchoolClick}
        />
    );
};

export default SchoolsContainer;
