import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Pobierz zapisany język lub użyj domyślnego
const savedLanguage = localStorage.getItem('i18nextLng') || 'pl';
// Od razu ustaw atrybut lang w HTML
document.documentElement.setAttribute('lang', savedLanguage);

const resources = {
    en: {
        translation: {
            // MenuContainer
            "home": "Home",
            "component1": "Component 1",
            "component2": "Component 2",
            "component3": "Component 3",
            "adminTools": "Admin Tools",
            "users": "Users",
            "addUser": "Add User",
            "component6": "Component 6",
            "component7": "Component 7",
            "logout": "Logout",
            "tokenExpiresIn": "Token expires in: {{time}}",
            "Language": "Language",
            "English": "English",
            "Polski": "Polski",
            // UsersTable
            "ID": "ID",
            "username": "Username",
            "name": "Name",
            "surname": "Surname",
            "email": "Email",
            "role": "Role",
            "active": "Active",
            "department": "Department",
            "actions": "Actions",
            "edit": "Edit",
            "delete": "Delete",
            "loading": "Loading...",
            "yes": "Yes",
            "no": "No",
            "confirmDeletion": "Confirm Deletion",
            "confirmDeletionMessage": "Are you sure you want to delete this user?",
            "cancel": "Cancel",
            "success": "Success",
            "error": "Error",
            // UserForm
            "password": "Password",
            "selectRole": "Select Role",
            "selectDepartment": "Select Department",
            "updateUser": "Update User",
            // DepartmentForm
            "departments": "Departments",
            "department_name": "Department",
            "department_description": "Department Description",
            "department_year": "Department Year",
            "addDepartment": "Add Department",
            "department_confirmDeletionMessage": "Are you sure you want to delete this department?",
            //
            "courses": "Courses",
            "topics": "Topics",
            "tasks": "Tasks",
            // Exam related
            "exams": "Exams",
            "activeExamAlert": "Active exam: {{examName}} (Attempts: {{attemptsInfo}})",
            "examSchedules": "Exam Schedules",
            "examName": "Exam Name",
            "status": "Status",
            "dates": "Dates",
            "settings": "Settings",
            "students": "Students",
            "timeLimit": "Time Limit",
            "attempts": "Attempts",
            "resultsHidden": "Results Hidden",
            "manageStudents": "Manage Students",
            "manageExamStudents": "Manage Exam Students",
            "addStudents": "Add Students",
            "student": "Student",
            "customDates": "Custom Dates",
            "usingDefaultDates": "Using Default Dates",
            "customSettings": "Custom Settings",
            "selectStudents": "Select Students",
            "startDate": "Start Date",
            "endDate": "End Date",
            "attemptsAllowed": "Attempts Allowed",
            "reason": "Reason",

            // User Info related
            "userInfo": "User Info",
            "userInformation": "User Information",
            "changePassword": "Change Password",
            "currentPassword": "Current Password",
            "newPassword": "New Password",
            "confirmPassword": "Confirm Password",
            "change": "Change",

            // Schools and Departments
            "schools": "Schools",
            "description": "Description",
            "year": "Year",
            "address": "Address",
            "departmentsCount": "Departments Count",
            "info": "Info",

            // Exam Start Modal
            "startExamConfirmation": "Start Exam Confirmation",
            "timeSettings": "Time Settings",
            "examTimeLimit": "Time limit: {{minutes}} minutes",
            "examEndTime": "Exam end time",
            "examRules": "Exam Rules",
            "cannotPauseExam": "You cannot pause the exam once started",
            "resultsAvailableAfterCompletion": "Results will be available after completion",
            "resultsAvailableLater": "Results will be available later",
            "examStartWarning": "Please make sure you have stable internet connection and enough time to complete the exam",
            "startExam": "Start Exam",
            "all": "All",
            "current": "Current",
            "upcoming": "Upcoming",
            "past": "Past",
            "examManagement": "Exam Management",
            "myExams": "My Exams",
            "viewResults": "View Results",
            "inactive": "Inactive",

            // Sync changes translations
            "checkForChanges": "Check for changes",
            "syncChanges": "Synchronize Changes",
            "checkingChanges": "Checking for changes...",
            "allCoursesSync": "All courses are synchronized",
            "syncRequired": "Sync required",
            "newTopics": "New topics",
            "noNewTopics": "No new topics",
            "changesInTopics": "Changes in topics",
            "newTasks": "New tasks",
            "tasksCount": "{{count}} tasks",
            "syncing": "Synchronizing...",
            "syncSelected": "Sync selected",

            //
            "schoolDepartments": "School Departments",
            "usersCount": "Users Count",
            "assignDepartmentsToSchool": "Assign Departments to School",
            "assignExisting": "Assign Existing",
            "back": "Back",
            "editDepartment": "Edit Department",
            "departmentUsers": "Department Users",
            "addExamSchedule": "Add Exam Schedule",
            "searchExams": "Search Exams",
            "showOnlyActive": "Show Only Active",
            "isActive": "Is Active",
            "canSeeResults": "Can See Results",
            "selectExam": "Select Exam",
            "editExamSchedule": "Edit Exam Schedule",
            "resultsVisible": "Results Visible",
            "save": "Save",
            "noTimeLimit": "No Time Limit",
            "participants": "Participants",
            "addCourse": "Add Course",
            "courseName": "Course Name",
            "courseDescription": "Course Description",
            "addTopic": "Add Topic",
            "topicType": "Topic Type",
            "examTopic": "Exam Topic",
            "regularTopic": "Regular Topic",
            "programmingLanguage": "Programming Language",
            "selectLanguage": "Select Language",
            "isVisible": "Is Visible",
            "visibleFrom": "Visible From",
            "visibleUntil": "Visible Until",
            "add": "Add",
            "backToCourses": "Back to Courses",
            "language": "Language",
            "type": "Type",
            "visibility": "Visibility",
            "topic": "Topic",
            "exam": "Exam",
            "visible": "Visible",
            "hidden": "Hidden",
            "addSchool": "Add School",
            "schoolName": "School Name",
            "schoolDescription": "School Description",
            "schoolAddress": "School Address",
            "endExamConfirmation": "End Exam Confirmation",
            "endExamConfirmationMessage": "Are you sure you want to end the exam?",
            "endExam": "End Exam",
            "timeRemaining": "Time Remaining",
            "continueExam": "Continue Exam",
            "ongoing": "Ongoing",
            "completed": "Completed",
            "currentTimeLimit": "Current Time Limit",
            "currentCanSeeResults": "Can Currently See Results",
            "currentStudents": "Current Students",
            "close": "Close",
            "addSelectedStudents": "Add Selected Students",
            "remove": "Remove",
            "addExam": "Add Exam",

            // LoginPage
            "login": "Login",
            "loginTitle": "Sign in",
            "enterUsername": "Enter username",
            "enterPassword": "Enter password",
            "loggingIn": "Logging in...",

            // Progress Modal
            "changeHistory": "Change History",
            "loadingHistory": "Loading history...",
            "failedToLoadHistory": "Failed to load change history.",
            "noChangeHistory": "No change history for this task.",
            "tabSwitches": "Tab switches",
            "windowActive": "Window active",
            "visibilityStatus": "Visibility status",
            "snapshot": "Snapshot",
            "of": "of",
            "restoreVersion": "Restore this version",
            "codeRestored": "// Code restored from {{timestamp}}",

        }
    },
    pl: {
        translation: {
            // MenuContainer
            "home": "Strona główna",
            "component1": "Komponent 1",
            "component2": "Komponent 2",
            "component3": "Komponent 3",
            "adminTools": "Narzędzia Admina",
            "users": "Użytkownicy",
            "addUser": "Dodaj użytkownika",
            "component6": "Komponent 6",
            "component7": "Komponent 7",
            "logout": "Wyloguj",
            "tokenExpiresIn": "Token wygasa za: {{time}}",
            "Language": "Język",
            "English": "Angielski",
            "Polski": "Polski",
            // UsersTable
            "ID": "ID",
            "username": "Nazwa użytkownika",
            "name": "Imię",
            "surname": "Nazwisko",
            "email": "Email",
            "role": "Rola",
            "active": "Aktywny",
            "department": "Oddział",
            "actions": "Akcje",
            "edit": "Edytuj",
            "delete": "Usuń",
            "loading": "Ładowanie...",
            "yes": "Tak",
            "no": "Nie",
            "confirmDeletion": "Potwierdzenie usunięcia",
            "confirmDeletionMessage": "Czy na pewno chcesz usunąć tego użytkownika?",
            "cancel": "Anuluj",
            "success": "Sukces",
            "error": "Błąd",
            // UserForm
            "password": "Hasło",
            "selectRole": "Wybierz rolę",
            "selectDepartment": "Wybierz oddział",
            "updateUser": "Zaktualizuj użytkownika",
            // DepartmentForm
            "departments": "Oddziały",
            "department_name": "Nazwa oddziału",
            "department_description": "Opis oddziału",
            "department_year": "Rok Oddziału",
            "addDepartment": "Dodaj oddział",
            "department_confirmDeletionMessage": "Czy na pewno chcesz usunąć ten oddział?",
            //
            "courses": "Kursy",
            "topics": "Tematy",
            "tasks": "Zadania",
            // Exam related
            "exams": "Egzaminy",
            "activeExamAlert": "Aktywny egzamin: {{examName}} (Próby: {{attemptsInfo}})",
            "examSchedules": "Harmonogram Egzaminów",
            "examName": "Nazwa Egzaminu",
            "status": "Status",
            "dates": "Daty",
            "settings": "Ustawienia",
            "students": "Studenci",
            "timeLimit": "Limit Czasu",
            "attempts": "Próby",
            "resultsHidden": "Wyniki Ukryte",
            "manageStudents": "Zarządzaj Studentami",
            "manageExamStudents": "Zarządzaj Studentami Egzaminu",
            "addStudents": "Dodaj Studentów",
            "student": "Student",
            "customDates": "Niestandardowe Daty",
            "usingDefaultDates": "Używa Domyślnych Dat",
            "customSettings": "Niestandardowe Ustawienia",
            "selectStudents": "Wybierz Studentów",
            "startDate": "Data Rozpoczęcia",
            "endDate": "Data Zakończenia",
            "attemptsAllowed": "Dozwolone Próby",
            "reason": "Powód",

            // User Info related
            "userInfo": "Informacje o Użytkowniku",
            "userInformation": "Informacje o Użytkowniku",
            "changePassword": "Zmień Hasło",
            "currentPassword": "Obecne Hasło",
            "newPassword": "Nowe Hasło",
            "confirmPassword": "Potwierdź Hasło",
            "change": "Zmień",

            // Schools and Departments
            "schools": "Szkoły",
            "description": "Opis",
            "year": "Rok",
            "address": "Adres",
            "departmentsCount": "Liczba Oddziałów",
            "info": "Informacje",

            // Exam Start Modal
            "startExamConfirmation": "Potwierdzenie rozpoczęcia egzaminu",
            "timeSettings": "Ustawienia czasu",
            "examTimeLimit": "Limit czasu: {{minutes}} minut",
            "examEndTime": "Czas zakończenia egzaminu",
            "examRules": "Zasady egzaminu",
            "cannotPauseExam": "Nie można wstrzymać egzaminu po jego rozpoczęciu",
            "resultsAvailableAfterCompletion": "Wyniki będą dostępne po zakończeniu",
            "resultsAvailableLater": "Wyniki będą dostępne później",
            "examStartWarning": "Upewnij się, że masz stabilne połączenie internetowe i wystarczająco dużo czasu na ukończenie egzaminu",
            "startExam": "Rozpocznij egzamin",
            "all": "Wszystkie",
            "current": "Bieżące",
            "upcoming": "Nadchodzące",
            "past": "Zakończone",
            "examManagement": "Zarządzanie egzaminami",
            "myExams": "Moje egzaminy",
            "viewResults": "Zobacz wyniki",
            "inactive": "Nieaktywny",

            // Sync changes translations
            "checkForChanges": "Sprawdź zmiany",
            "syncChanges": "Synchronizuj Zmiany",
            "checkingChanges": "Sprawdzanie zmian...",
            "allCoursesSync": "Wszystkie kursy są zsynchronizowane",
            "syncRequired": "Wymagana synchronizacja",
            "newTopics": "Nowe tematy",
            "noNewTopics": "Brak nowych tematów",
            "changesInTopics": "Zmiany w tematach",
            "newTasks": "Nowe zadania",
            "tasksCount": "{{count}} zadań",
            "syncing": "Synchronizacja...",
            "syncSelected": "Synchronizuj wybrane",

            //
            "schoolDepartments": "Oddziały Szkoły",
            "usersCount": "Liczba Użytkowników",
            "assignDepartmentsToSchool": "Przypisz Oddziały do Szkoły",
            "assignExisting": "Przypisz Istniejące",
            "back": "Powrót",
            "editDepartment": "Edytuj Oddział",
            "departmentUsers": "Użytkownicy Oddziału",
            "addExamSchedule": "Dodaj Harmonogram Egzaminu",
            "searchExams": "Szukaj Egzaminów",
            "showOnlyActive": "Pokaż Tylko Aktywne",
            "isActive": "Jest Aktywny",
            "canSeeResults": "Może Widzieć Wyniki",
            "selectExam": "Wybierz Egzamin",
            "editExamSchedule": "Edytuj Harmonogram Egzaminu",
            "resultsVisible": "Wyniki Widoczne",
            "save": "Zapisz",
            "noTimeLimit": "Bez Limitu Czasu",
            "participants": "Uczestnicy",
            "addCourse": "Dodaj Kurs",
            "courseName": "Nazwa Kursu",
            "courseDescription": "Opis Kursu",
            "addTopic": "Dodaj Temat",
            "topicType": "Typ Tematu",
            "examTopic": "Temat Egzaminacyjny",
            "regularTopic": "Temat Regularny",
            "programmingLanguage": "Język Programowania",
            "selectLanguage": "Wybierz Język",
            "isVisible": "Jest Widoczny",
            "visibleFrom": "Widoczny Od",
            "visibleUntil": "Widoczny Do",
            "add": "Dodaj",
            "backToCourses": "Powrót do Kursów",
            "language": "Język",
            "type": "Typ",
            "visibility": "Widoczność",
            "topic": "Temat",
            "exam": "Egzamin",
            "visible": "Widoczny",
            "hidden": "Ukryty",
            "addSchool": "Dodaj Szkołę",
            "schoolName": "Nazwa Szkoły",
            "schoolDescription": "Opis Szkoły",
            "schoolAddress": "Adres Szkoły",
            "endExamConfirmation": "Potwierdzenie zakończenia egzaminu",
            "endExamConfirmationMessage": "Czy na pewno chcesz zakończyć egzamin?",
            "endExam": "Zakończ egzamin",
            "timeRemaining": "Pozostały czas",
            "continueExam": "Kontynuuj egzamin",
            "ongoing": "W toku",
            "completed": "Zakończony",
            "currentTimeLimit": "Aktualny limit czasu",
            "currentCanSeeResults": "Obecnie może zobaczyć wyniki",
            "currentStudents": "Aktualni studenci",
            "close": "Zamknij",
            "addSelectedStudents": "Dodaj wybranych studentów",
            "remove": "Usuń",
            "addExam": "Dodaj egzamin",

            // LoginPage
            "login": "Zaloguj",
            "loginTitle": "Logowanie",
            "enterUsername": "Wprowadź nazwę użytkownika",
            "enterPassword": "Wprowadź hasło",
            "loggingIn": "Logowanie...",

            // Progress Modal
            "changeHistory": "Historia zmian",
            "loadingHistory": "Ładowanie historii...",
            "failedToLoadHistory": "Nie udało się pobrać historii zmian.",
            "noChangeHistory": "Brak historii zmian dla tego zadania.",
            "tabSwitches": "Przełączenia zakładek",
            "windowActive": "Okno aktywne",
            "visibilityStatus": "Stan widoczności",
            "snapshot": "Snapshot",
            "of": "z",
            "restoreVersion": "Przywróć tę wersję",
            "codeRestored": "// Przywrócono kod z {{timestamp}}",

        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage, // Użyj zapisanego języka
        fallbackLng: 'pl',
        interpolation: {
            escapeValue: false
        }
    });

// Zapisuj wybrany język do localStorage przy każdej zmianie
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('i18nextLng', lng);
    document.documentElement.setAttribute('lang', lng);
});

export default i18n;
