import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import TasksListLayout from '../components/TasksListLayout';
import { usePost } from '../../../hooks';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    position: sticky;
    bottom: 0;
    background-color: var(--bs-body-bg);
    padding-top: 10px;
    border-top: 1px solid var(--bs-border-color);
`;

const ControlButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: ${props => props.$show ? 1 : 0};
    transition: all 0.2s ease-in-out;
    width: ${props => props.$show ? 'auto' : '0'};
    overflow: hidden;
    margin-left: ${props => props.$show ? '0.5rem' : '0'};

    button {
        ${props => !props.$show && `
            width: 0;
            padding: 0;
            margin: 0;
            border: 0;
        `}
    }
`;

const AdminTasksList = ({
                            tasksList,
                            loading,
                            error,
                            selectedTaskId,
                            onSelect,
                            onRefresh,
                            onAddClick,
                            disabled
                        }) => {
    const [showControls, setShowControls] = useState(false);
    const { postData } = usePost();

    const handleMoveTask = async (taskId, direction) => {
        try {
            await postData(`ADMIN_TASK_MOVE_${direction.toUpperCase()}`, null, [taskId]);
            onRefresh();
        } catch (error) {
            console.error(`Error moving task ${direction}:`, error);
        }
    };

    const handleDeleteTask = async (taskId) => {
        if (window.confirm('Czy na pewno chcesz usunąć to zadanie?')) {
            try {
                await postData('ADMIN_TASK_DELETE', null, [taskId], 'DELETE'); // dodajemy metodę DELETE
                onRefresh();
                if (selectedTaskId === taskId) {
                    onSelect(null);
                }
            } catch (error) {
                console.error('Error deleting task:', error);
            }
        }
    };

    const renderControls = (task) => (
        <ControlButtons $show={showControls && !disabled}>
            <Button
                size="sm"
                variant="outline-secondary"
                onClick={(e) => {
                    e.stopPropagation();
                    handleMoveTask(task.id, 'up');
                }}
                disabled={!task.prev_id || disabled}
            >
                ↑
            </Button>
            <Button
                size="sm"
                variant="outline-secondary"
                onClick={(e) => {
                    e.stopPropagation();
                    handleMoveTask(task.id, 'down');
                }}
                disabled={!task.next_id || disabled}
            >
                ↓
            </Button>
            <Button
                size="sm"
                variant="outline-danger"
                onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTask(task.id);
                }}
                disabled={disabled}
            >
                🗑️
            </Button>
        </ControlButtons>
    );

    return (
        <>
            <TasksListLayout
                items={tasksList}
                loading={loading}
                error={error}
                selectedId={selectedTaskId}
                onSelect={onSelect}
                renderControls={renderControls}
                disabled={disabled}
            />
            <ButtonsContainer>
                <Button
                    variant="primary"
                    onClick={() => {
                        //console.log('AdminTasksList - Add button clicked');
                        onAddClick();
                    }}
                    className="mb-2"
                    disabled={disabled}
                >
                    Dodaj nowe
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => setShowControls(!showControls)}
                    disabled={disabled}
                >
                    {showControls ? 'Zakończ sortowanie' : 'Sortuj zadania'}
                </Button>
            </ButtonsContainer>
        </>
    );
};

export default AdminTasksList;
