import React, { useRef, useEffect, useCallback } from 'react';
import AceEditor from 'react-ace';
//import { useSnapshotSender } from '../../../hooks/useSnapshotSender';
import ace from 'ace-builds';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-min-noconflict/ext-language_tools';

// Konfiguracje języków i motywy
const LANGUAGE_IMPORTS = [
    'html', 'javascript', 'php', 'python', 'java', 'csharp',
    'c_cpp', 'ruby', 'golang', 'rust', 'typescript'
];

const THEME_IMPORTS = [
    'github', 'monokai', 'solarized_dark', 'solarized_light', 'twilight',
    'tomorrow', 'kuroir', 'xcode', 'textmate', 'terminal'
];

// Importy języków
LANGUAGE_IMPORTS.forEach(lang => {
    require(`ace-builds/src-min-noconflict/mode-${lang}`);
});

// Importy motywów
THEME_IMPORTS.forEach(theme => {
    require(`ace-builds/src-min-noconflict/theme-${theme}`);
});

const languageToMode = {
    php: 'php',
    python: 'python',
    javascript: 'javascript',
    java: 'java',
    csharp: 'csharp',
    cpp: 'c_cpp',
    ruby: 'ruby',
    go: 'golang',
    rust: 'rust',
    typescript: 'typescript'
};

const jsSnippets = {
    "script": {
        prefix: "script",
        body: "<script>\n\t$1\n</script>",
        description: "HTML Script tag"
    },
    "style": {
        prefix: "style",
        body: "<style>\n\t$1\n</style>",
        description: "HTML Style tag"
    },
    "html": {
        prefix: "html",
        body: "<!DOCTYPE html>\n<html lang='pl'>\n<head>\n\t<title>$1</title>\n</head>\n<body>\n\t$2\n</body>\n</html>",
        description: "HTML basic structure"
    },
    "div": {
        prefix: "div",
        body: "<div>$1</div>",
        description: "HTML div element"
    }
};

const TasksCodeEditor = ({
                             theme,
                             editorContent,
                             setEditorContent,
                             taskId,
                             isExample,
                             language,
                             isEditing,
                             canEdit,
                             fontSize = 15,
                             userRole,
                             readOnly,
                             taskType
                         }) => {
    const editorRef = useRef(null);
    const mode = languageToMode[language] || 'text';
    const isReadOnly = readOnly || (isExample && (!isEditing || !canEdit));
    const isAdmin = userRole === 'admin';
    const isTeacher = userRole === 'teacher';
    const canPaste = isAdmin || isTeacher;
    const isJavaScript = language === 'javascript';

    //useSnapshotSender(taskId, editorContent, isExample, taskType);

    // Konfiguracja JavaScript i snippetów
    const configureJavaScript = useCallback((editor) => {
        if (!isJavaScript) return;

        editor.session.setMode('ace/mode/html');
        editor.setBehavioursEnabled(true);

        const snippetManager = ace.require("ace/snippets").snippetManager;
        Object.entries(jsSnippets).forEach(([name, snippet]) => {
            snippetManager.register([{
                name,
                tabTrigger: snippet.prefix,
                content: snippet.body
            }], 'html');
        });
    }, [isJavaScript]);

    // Konfiguracja blokady wklejania
    const configurePasteBlocking = useCallback((editor) => {
        if (canPaste) return;

        const preventPaste = () => {
            return false;
        };

        editor.commands.addCommand({
            name: "paste",
            bindKey: { win: "Ctrl-V", mac: "Command-V" },
            exec: preventPaste
        });

        editor.container.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            return false;
        });

        editor.on('paste', () => '');
    }, [canPaste]);

    useEffect(() => {
        if (!editorRef.current) return;

        const editor = editorRef.current.editor;

        configureJavaScript(editor);
        configurePasteBlocking(editor);

        return () => {
            if (editor && !canPaste) {
                editor.off('paste');
                editor.container.removeEventListener('contextmenu', () => {});
            }
        };
    }, [configureJavaScript, configurePasteBlocking, canPaste]);

    const handleChange = (newContent) => {
        if (!isReadOnly) {
            setEditorContent(newContent);
        }
    };

    return (
        <div className="flex-grow-1">
            <AceEditor
                ref={editorRef}
                mode={isJavaScript ? 'html' : mode}
                theme={theme}
                name="code-editor"
                useWorker={false}
                editorProps={{
                    $blockScrolling: true,
                }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2,
                    readOnly: isReadOnly,
                    behavioursEnabled: true,
                    showFoldWidgets: false,
                    highlightActiveLine: true,
                    highlightSelectedWord: true,
                    animatedScroll: true,
                    displayIndentGuides: true,
                    showGutter: true,
                    useSoftTabs: true,
                    fadeFoldWidgets: false,
                    showPrintMargin: false,
                    // usunięto showGutterIcons bo nie jest obsługiwane
                }}
                style={{ width: '100%', height: '100%' }}
                value={editorContent}
                onChange={handleChange}
                fontSize={fontSize}
                commands={[
                    {
                        name: 'expandSnippet',
                        bindKey: {win: 'Tab', mac: 'Tab'},
                        exec: (editor) => {
                            const snippetManager = ace.require("ace/snippets").snippetManager;
                            const expanded = snippetManager.expandWithTab(editor);
                            if (!expanded) {
                                editor.indent();
                            }
                            return true;
                        }
                    }
                ]}
            />
        </div>
    );
};

export default TasksCodeEditor;
