import React, { useState, useEffect } from 'react';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import TeacherCoursesView from './TeacherCoursesView';

const TeacherCoursesContainer = () => {
    const navigate = useNavigate();
    const { darkMode } = useTheme();

    const {
        data: courses,
        loading,
        error,
        fetchData: refreshCourses
    } = useFetch('TEACHER_COURSES');

    const { postData, loading: actionLoading, error: actionError } = usePost();

    const [showParticipants, setShowParticipants] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [showInstanceForm, setShowInstanceForm] = useState(false);
    const [instanceFormData, setInstanceFormData] = useState({ name: '' });
    const [showStats, setShowStats] = useState(false);

    const {
        data: students,
        loading: studentsLoading,
        error: studentsError
    } = useFetch(
        showParticipants ? 'TEACHER_COURSES_STUDENTS_GET' : undefined,
        showParticipants ? [selectedCourseId] : undefined,
        showParticipants
    );

    useEffect(() => {
        if (students && students.length > 0) {
            const enrolledStudents = students
                .filter(student => student.enrolled)
                .map(student => student.id);
            setSelectedStudents(enrolledStudents);
        }
    }, [students]);

    const handleShowParticipants = (courseId) => {
        setSelectedCourseId(courseId);
        setShowParticipants(true);
    };

    const handleCloseParticipants = () => {
        setShowParticipants(false);
        setSelectedCourseId(null);
        setSelectedStudents([]);
    };

    const handleStudentSelect = (studentId) => {
        setSelectedStudents(prev => {
            if (prev.includes(studentId)) {
                return prev.filter(id => id !== studentId);
            }
            return [...prev, studentId];
        });
    };

    const handleSelectAll = (students) => {
        if (students.every(student => selectedStudents.includes(student.id))) {
            setSelectedStudents([]);
        } else {
            setSelectedStudents(students.map(student => student.id));
        }
    };

    const handleSaveParticipants = async () => {
        if (!selectedCourseId) return;

        try {
            await postData(
                'TEACHER_COURSES_STUDENTS_PUT',
                { students: selectedStudents },
                [selectedCourseId],
                'PUT'
            );
            handleCloseParticipants();
            refreshCourses();
        } catch (err) {
            console.error('Failed to save participants:', err);
        }
    };

    const handleShowInstanceForm = (course) => {
        setSelectedCourseId(course.id);
        setInstanceFormData({ name: course.name });
        setShowInstanceForm(true);
    };

    const handleCloseInstanceForm = () => {
        setShowInstanceForm(false);
        setSelectedCourseId(null);
        setInstanceFormData({ name: '' });
    };

    const handleInstanceSubmit = async (event) => {
        event.preventDefault();
        if (!selectedCourseId) return;

        try {
            await postData(
                'TEACHER_COURSE_INSTANCE_UPDATE',
                instanceFormData,
                [selectedCourseId]
            );
            handleCloseInstanceForm();
            refreshCourses();
        } catch (err) {
            console.error('Failed to update course instance:', err);
        }
    };

    const courseActions = {
        onManageParticipants: handleShowParticipants,
        onViewTopics: (courseId) => navigate(`/teacher/topics/${courseId}`),
        onEdit: handleShowInstanceForm,
        onShowStats: (courseId) => {
            setSelectedCourseId(courseId);
            setShowStats(true);
        }
    };

    return (
        <TeacherCoursesView
            courses={courses}
            loading={loading || actionLoading}
            error={error || actionError}
            darkMode={darkMode}
            actions={courseActions}
            showParticipants={showParticipants}
            selectedCourseId={selectedCourseId}
            students={students || []}
            selectedStudents={selectedStudents}
            studentsLoading={studentsLoading}
            studentsError={studentsError}
            onCloseParticipants={handleCloseParticipants}
            onStudentSelect={handleStudentSelect}
            onSelectAll={handleSelectAll}
            onSaveParticipants={handleSaveParticipants}
            showInstanceForm={showInstanceForm}
            instanceFormData={instanceFormData}
            onCloseInstanceForm={handleCloseInstanceForm}
            onInstanceFormChange={(e) => setInstanceFormData({
                ...instanceFormData,
                [e.target.name]: e.target.value
            })}
            onInstanceFormSubmit={handleInstanceSubmit}
            showStats={showStats}
            onCloseStats={() => {
                setShowStats(false);
                setSelectedCourseId(null);
            }}
        />
    );
};

export default TeacherCoursesContainer;
