import { useState } from 'react';
import axios from 'axios';
import config from '../config/config';

const useApiConfig = () => {
    const [endpoints, setEndpoints] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchConfig = async (token) => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.apiUrl}/config`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setEndpoints(response.data);
            setError(null);
            return response.data;
        } catch (err) {
            setError(err.message);
            setEndpoints(null);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return { endpoints, loading, error, fetchConfig };
};

export default useApiConfig;
