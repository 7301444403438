const HtmlPreview = ({ output, theme, darkMode, runCounter }) => {
    const wrappedCode = `
    <!DOCTYPE html>
    <html ${darkMode ? 'data-bs-theme="dark"' : ''}>
    <head>
        <style>
            body {
                background-color: ${theme.iframe.background};
                color: ${theme.pre.text};
                margin: 0;
                padding: 8px;
                transition: background-color 0.3s ease, color 0.3s ease;
            }
        </style>
        <script>
            const originalConsole = {
                log: console.log,
                error: console.error,
                warn: console.warn,
                info: console.info,
                table: console.table
            };

            console.log = function(...args) {
                originalConsole.log.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'log',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.error = function(...args) {
                originalConsole.error.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'error',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.warn = function(...args) {
                originalConsole.warn.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'warn',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.info = function(...args) {
                originalConsole.info.apply(console, args);
                window.parent.postMessage({
                    type: 'console',
                    level: 'info',
                    content: args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ')
                }, '*');
            };

            console.table = function(data, columns) {
                originalConsole.table.apply(console, arguments);
                
                let tableData = [];
                let headers = [];
                
                if (Array.isArray(data)) {
                    if (data.length > 0) {
                        if (typeof data[0] === 'object' && data[0] !== null) {
                            headers = columns || Object.keys(data[0]);
                            tableData = data.map(item => {
                                let row = {};
                                headers.forEach(header => {
                                    row[header] = item[header];
                                });
                                return row;
                            });
                        } else {
                            headers = ['(index)', 'Value'];
                            tableData = data.map((item, index) => ({
                                '(index)': index,
                                'Value': item
                            }));
                        }
                    }
                } else if (typeof data === 'object' && data !== null) {
                    headers = ['Key', 'Value'];
                    tableData = Object.entries(data).map(([key, value]) => ({
                        'Key': key,
                        'Value': typeof value === 'object' ? JSON.stringify(value) : value
                    }));
                }

                window.parent.postMessage({
                    type: 'console',
                    level: 'table',
                    isTable: true,
                    tableData: {
                        headers,
                        rows: tableData
                    },
                    content: 'Table Output'
                }, '*');
            };

            window.onerror = function(msg, url, line, col, error) {
                // Odejmujemy odpowiednią liczbę linii nagłówka iframe
                const headerLines = 134;
                const actualLine = line - headerLines;
                
                window.parent.postMessage({
                    type: 'console',
                    level: 'error',
                    content: \`\${msg} at \${actualLine}:\${col})\`
                }, '*');
                return false;
            };

            window.addEventListener('unhandledrejection', function(event) {
                window.parent.postMessage({
                    type: 'console',
                    level: 'error',
                    content: \`Unhandled Promise rejection: \${event.reason}\`
                }, '*');
            });
        </script>
    </head>
    <body>
        ${output}
    </body>
    </html>
    `;

    return (
        <iframe
            key={runCounter}
            srcDoc={wrappedCode}
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
                backgroundColor: theme.iframe.background,
                transition: 'background-color 0.3s ease'
            }}
            title="Code Output"
        />
    );
};

export default HtmlPreview;
