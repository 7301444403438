import React from 'react';
import TasksContentLayout from '../components/TasksContentLayout';
import ExamTimer from '../../exams/components/ExamTimer';
import styled from 'styled-components';

const NoTaskMessage = styled.p`
    padding: 1rem;
`;

const StudentTaskContent = ({
                                loading,
                                error,
                                task,
                                isExam,
                                examStartTime,
                                examTimeLimit,
                                onExamTimeEnd,
                                onExamEnd
                            }) => {
    const examTimerComponent = isExam && (
        <ExamTimer
            startTime={examStartTime}
            timeLimit={examTimeLimit}
            onTimeEnd={onExamTimeEnd}
            onExamEnd={onExamEnd}
        />
    );

    if (loading || !task) {
        return (
            <TasksContentLayout
                examTimer={examTimerComponent}
            >
                <NoTaskMessage>Wybierz zadanie, aby zobaczyć jego treść.</NoTaskMessage>
            </TasksContentLayout>
        );
    }

    // Zawartość górnego panelu tylko dla Pythona
    const topPanelContent = task.language?.toLowerCase() === 'python' ? (
        <div>Python task visualization panel</div>
    ) : null;

    return (
        <TasksContentLayout
            title={task.name}
            language={task.language}
            description={task.description}
            content={task.content}
            isEditing={false}
            examTimer={examTimerComponent}
            topPanelContent={topPanelContent}
        />
    );
};

export default StudentTaskContent;
