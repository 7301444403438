import React from 'react';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';
import { useTranslation } from 'react-i18next';

const UsersList = ({
                       filters = {},
                       formConfig = {},
                       viewConfig = {},
                       data,
                       loading,
                       error,
                       onDelete,
                       onSave,
                       deleteError,
                       deleteResponse,
                       roles,
                       departments,
                       statusOptions,
                       sortConfig,
                       onSort,
                       showForm,
                       setShowForm
                   }) => {
    const { t } = useTranslation();
    const [showDelete, setShowDelete] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [formData, setFormData] = React.useState({
        username: '',
        password: '',
        email: '',
        name: '',
        surname: '',
        role_id: '',
        status: 'active',
        is_super: false,
        school_id: formConfig.schoolId || '',
        department_ids: formConfig.departmentIds || []
    });

    const handleShowForm = (user = null) => {
        if (user) {
            setFormData({
                username: user.username,
                password: '',
                email: user.email,
                name: user.name,
                surname: user.surname,
                role_id: user.role_id.toString(),
                status: user.status,
                is_super: user.is_super,
                school_id: formConfig.schoolId || user.school_id?.toString() || '',
                department_ids: formConfig.departmentIds || user.departments?.map(d => d.id.toString()) || []
            });
            setSelectedUser(user);
            setEditMode(true);
        } else {
            setFormData({
                username: '',
                password: '',
                email: '',
                name: '',
                surname: '',
                role_id: '',
                status: 'active',
                is_super: false,
                school_id: formConfig.schoolId || '',
                department_ids: formConfig.departmentIds || []
            });
            setEditMode(false);
        }
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setSelectedUser(null);
    };

    const handleShowDelete = (user) => {
        setSelectedUser(user);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setSelectedUser(null);
    };

    const handleConfirmDelete = () => {
        onDelete(selectedUser.id);
        handleCloseDelete();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onSave(formData, editMode ? selectedUser.id : null);
        handleCloseForm();
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const defaultColumns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'username', label: 'username', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'surname', label: 'surname', sortable: true },
        { key: 'email', label: 'email', sortable: true },
        { key: 'role', label: 'role', sortable: true },
        {
            key: 'status',
            label: 'status',
            sortable: true,
            render: (user) => user.status
        }
    ];

    if (!viewConfig.hideColumns?.includes('departments')) {
        defaultColumns.push({
            key: 'departments',
            label: 'departments',
            sortable: true,
            render: (user) => user.departments?.map(d => d.name).join(', ') || '-'
        });
    }

    const defaultActions = [
        { label: 'edit', variant: 'secondary', onClick: handleShowForm },
        { label: 'delete', variant: 'danger', onClick: handleShowDelete }
    ].filter(action => !viewConfig.hideActions?.includes(action.label));

    const formFields = [
        { name: 'username', type: 'text', label: 'username', required: true },
        { name: 'password', type: 'password', label: 'password', required: !editMode },
        { name: 'email', type: 'email', label: 'email', required: true },
        { name: 'name', type: 'text', label: 'name', required: true },
        { name: 'surname', type: 'text', label: 'surname', required: true },
        {
            name: 'role_id',
            type: 'select',
            label: 'role',
            required: true,
            options: formConfig.allowedRoles
                ? roles.filter(role => formConfig.allowedRoles.includes(role.name))
                : roles,
            placeholder: 'selectRole'
        },
        {
            name: 'status',
            type: 'select',
            label: 'status',
            required: true,
            options: statusOptions,
            placeholder: 'selectStatus'
        }
    ];

    if (!formConfig.hideDepartmentField) {
        formFields.push({
            name: 'department_ids',
            type: 'checkboxGroup',
            label: 'departments',
            options: departments,
            placeholder: 'selectDepartments',
            disabled: formConfig.departmentIds?.length > 0 || !formData.school_id
        });
    }

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('confirmDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={deleteError}
                actionResponse={deleteResponse}
                title={viewConfig.title || "users"}
                subtitle={viewConfig.subtitle}
                data={data}
                columns={defaultColumns}
                actions={defaultActions}
                headerButtons={[
                    { label: 'addUser', variant: 'primary', onClick: () => handleShowForm() }
                ]}
                sortConfig={sortConfig}
                onSort={onSort}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={handleCloseForm}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                title="User"
                fields={formFields}
            />

            <DeleteModal
                show={showDelete}
                handleClose={handleCloseDelete}
                handleConfirm={handleConfirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default UsersList;
