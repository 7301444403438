import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetch, usePost } from '../../hooks';
import SchoolsList from './SchoolsList';

const SchoolsListContainer = ({
                                  filters = {},
                                  formConfig = {},
                                  viewConfig = {},
                                  onSchoolClick
                              }) => {
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    const { data, loading, error, fetchData } = useFetch('ADMIN_SCHOOLS');
    const { data: statusesData } = useFetch('STATUSES');
    const { postData, response: actionResponse, error: actionError, loading: actionLoading } = usePost();
    const navigate = useNavigate();

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleDelete = async (schoolId) => {
        try {
            await postData('ADMIN_SCHOOL_DELETE', null, [schoolId]);
            fetchData();
        } catch (error) {
            console.error('Failed to delete school:', error);
        }
    };

    const handleSave = async (formData, schoolId = null) => {
        try {
            const dataToSubmit = {
                ...formData,
                capacity: formData.capacity ? parseInt(formData.capacity) : null,
                school_year: parseInt(formData.school_year)
            };

            if (schoolId) {
                await postData('ADMIN_SCHOOL_UPDATE', dataToSubmit, [schoolId], 'PUT');
            } else {
                await postData('ADMIN_SCHOOL_ADD', dataToSubmit);
            }
            fetchData();
        } catch (error) {
            console.error('Failed to save school:', error);
        }
    };

    // Filtrowanie i sortowanie danych
    const filterAndSortData = (schools) => {
        if (!schools) return [];

        let filteredSchools = schools;

        // Aplikowanie filtrów
        if (filters.status) {
            filteredSchools = filteredSchools.filter(school => school.status === filters.status);
        }

        // Sortowanie
        return [...filteredSchools].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (aValue === undefined || aValue === null) aValue = '';
            if (bValue === undefined || bValue === null) bValue = '';

            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const sortedAndFilteredData = filterAndSortData(data?.schools);
    const statusOptions = statusesData?.school?.map(status => ({
        id: status.value,
        name: status.label
    })) || [];

    return (
        <SchoolsList
            filters={filters}
            formConfig={formConfig}
            viewConfig={viewConfig}
            data={sortedAndFilteredData}
            loading={loading || actionLoading}
            error={error}
            deleteError={actionError}
            deleteResponse={actionResponse}
            onDelete={handleDelete}
            onSave={handleSave}
            statusOptions={statusOptions}
            sortConfig={sortConfig}
            onSort={handleSort}
            onSchoolClick={(school) => navigate(`/admin/schools/${school.id}`)}
        />
    );
};

export default SchoolsListContainer;
