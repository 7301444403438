import React from 'react';
import UsersListContainer from './UsersListContainer';

const UsersContainer = () => {
    const viewConfig = {
        title: 'allUsers',
        hideColumns: []
    };

    return (
        <UsersListContainer
            viewConfig={viewConfig}
        />
    );
};

export default UsersContainer;
