import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { RunnerFactory } from '../components/runners/RunnerFactory';
import TeacherTasksView from './TeacherTasksView';

const TeacherTasksContainer = () => {
    const { topicId } = useParams();
    const location = useLocation();
    const { isExam, examId } = location.state || {};
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [consoleOutput, setConsoleOutput] = useState([]);

    // Pobieranie listy zadań
    const { data: tasksList, fetchData: refreshTasksList } = useFetch(
        isExam ? 'TEACHER_EXAM_TASKS' : 'TEACHER_TOPIC_TASKS',
        isExam ? [examId] : (topicId ? [topicId] : undefined)
    );

    // Pobieranie szczegółów zadania
    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId && !isExam ? 'TEACHER_TASK_DETAILS' : null,
        selectedTaskId && !isExam ? [selectedTaskId] : undefined
    );

    // Pobieranie szczegółów zadania egzaminacyjnego
    const {
        data: examTaskDetails,
        loading: examTaskLoading,
        error: examTaskError,
        fetchData: refreshExamTaskDetails
    } = useFetch(
        selectedTaskId && isExam && selectedStudent ? 'TEACHER_EXAM_STUDENT_TASK' : null,
        selectedTaskId && isExam && selectedStudent ? [examId, selectedStudent.id, selectedTaskId] : undefined
    );

    // Pobieranie szczegółów zadania studenta (dla zwykłych zadań)
    const {
        data: studentTaskDetails,
        loading: studentTaskLoading,
        error: studentTaskError,
        fetchData: refreshStudentTaskDetails
    } = useFetch(
        selectedTaskId && !isExam && selectedStudent ? 'TEACHER_TASK_STUDENT_PROGRESS' : null,
        selectedTaskId && !isExam && selectedStudent ? [selectedTaskId, selectedStudent.id] : undefined
    );

    // Ujednolicone pobieranie studentów
    const {
        data: studentsData,
        loading: studentsLoading,
        error: studentsError,
        fetchData: refreshStudents
    } = useFetch(
        selectedTaskId ? (isExam ? 'TEACHER_EXAM_SCHEDULE_STUDENTS' : 'TEACHER_TASK_STUDENTS') : null,
        selectedTaskId ? (isExam ? [examId] : [selectedTaskId]) : undefined
    );

    const { postData } = usePost();

    // Ujednolicone przetwarzanie danych studentów
    const processedStudents = React.useMemo(() => {
        if (!studentsData) {
            return { students: [], available_students: [] };
        }

        return {
            students: studentsData.students || [],
            available_students: studentsData.available_students || []
        };
    }, [studentsData]);

    const handleConsoleMessage = React.useCallback((message) => {
        setConsoleOutput(prev => {
            const newOutput = [...prev, {
                level: message.level || 'log',
                content: message.content,
                isTable: message.isTable,
                tableData: message.tableData
            }];
            setConsoleLogCount(newOutput.length);
            return newOutput;
        });
    }, []);

    const clearConsole = React.useCallback(() => {
        setConsoleOutput([]);
        setConsoleLogCount(0);
    }, []);

    const handleStudentSelect = async (student) => {
        if (!student || !student.id) {
            setSelectedStudent(null);
            setEditorContent(taskDetails?.code || '');
            setCodeOutput('');
            clearConsole();
            return;
        }

        setSelectedStudent(student);
        setCodeOutput('');
        clearConsole();

        if (!selectedTaskId) return;

        try {
            if (isExam) {
                await refreshExamTaskDetails();
            } else {
                await refreshStudentTaskDetails();
            }
        } catch (error) {
            console.error('Error fetching student code:', error);
        }
    };

    const handleResetCode = async () => {
        if (!selectedTaskId) return;

        try {
            const response = await postData(
                'TEACHER_TASK_RESET',
                null,
                [selectedTaskId]
            );

            if (response?.code !== undefined) {
                setEditorContent(response.code);
                setCodeOutput('');
                clearConsole();
            }
        } catch (error) {
            console.error('Błąd podczas resetowania kodu:', error);
            setCodeOutput('Błąd: Nie udało się zresetować kodu');
        }
    };

    const handleRunCode = async () => {
        clearConsole();

        try {
            // Próbujemy znaleźć język z różnych źródeł
            const taskLanguage = isExam
                ? (examTaskDetails?.language || tasksList?.find(task => task.id === selectedTaskId)?.language)
                : taskDetails?.language;

            // Sprawdzamy czy to JavaScript
            if (taskLanguage?.toLowerCase() === 'javascript') {
                setCodeOutput(editorContent);
                setOutputMode('html');
                setRunCounter(prev => prev + 1);
                return;
            }

            // Dla Pythona używamy dedykowanego runnera
            if (taskLanguage === 'python') {
                const runner = RunnerFactory.createRunner('python', {
                    darkMode
                });

                const result = await runner.run(editorContent, {
                    language: 'python'
                });

                setCodeOutput(result.output);
                setOutputMode(result.mode || 'text');
                setRunCounter(prev => prev + 1);
            } else {
                // Dla pozostałych języków używamy endpointu
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskLanguage
                });

                if (response.output) {
                    setCodeOutput(response.output);
                    setOutputMode('text');
                } else if (response.error) {
                    setCodeOutput(`Błąd: ${response.error}`);
                    setOutputMode('text');
                } else {
                    setCodeOutput('Brak wyniku lub wystąpił nieznany błąd.');
                    setOutputMode('text');
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (err) {
            console.error('Błąd wykonania kodu:', err);
            setCodeOutput(`Wystąpił błąd podczas wykonywania kodu: ${err.message}`);
            setOutputMode('text');
            setRunCounter(prev => prev + 1);
        }
    };

    const handleTaskSelect = (taskId) => {
        if (taskId === selectedTaskId) return;
        setSelectedTaskId(taskId);
        setCodeOutput('');
        clearConsole();
        setSelectedStudent(null);
    };

    // Efekty
    React.useEffect(() => {
        if (selectedTaskId) {
            if (isExam && selectedStudent) {
                refreshExamTaskDetails();
            } else if (!isExam) {
                refreshTaskDetails();
            }
            refreshStudents();
        }
    }, [selectedTaskId, isExam, selectedStudent, refreshExamTaskDetails, refreshTaskDetails, refreshStudents]);

    React.useEffect(() => {
        if (isExam && examTaskDetails && selectedStudent) {
            setEditorContent(examTaskDetails.code || '');
        } else if (!isExam && studentTaskDetails) {
            setEditorContent(studentTaskDetails.code || '');
        } else if (taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails, isExam, examTaskDetails, studentTaskDetails, selectedStudent]);

    React.useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    React.useEffect(() => {
        if (topicId || examId) {
            setSelectedTaskId(null);
            setSelectedStudent(null);
            setEditorContent('');
            setCodeOutput('');
            setConsoleOutput([]);
            setConsoleLogCount(0);
            refreshTasksList();
        }
    }, [topicId, examId]);

    return (
        <TeacherTasksView
            tasksList={tasksList}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={handleTaskSelect}
            taskDetails={isExam && !selectedStudent
                ? tasksList?.find(task => task.id === selectedTaskId)
                : isExam && selectedStudent
                    ? examTaskDetails
                    : taskDetails}
            taskLoading={isExam ? examTaskLoading : (selectedStudent ? studentTaskLoading : taskLoading)}
            taskError={isExam ? examTaskError : (selectedStudent ? studentTaskError : taskError)}
            theme={theme}
            setTheme={setTheme}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            outputMode={outputMode}
            setOutputMode={setOutputMode}
            codeOutput={codeOutput}
            onRunCode={handleRunCode}
            onResetCode={handleResetCode}
            runCounter={runCounter}
            showConsole={showConsole}
            setShowConsole={setShowConsole}
            consoleLogCount={consoleLogCount}
            consoleOutput={consoleOutput}
            onConsoleMessage={handleConsoleMessage}
            courseStudents={processedStudents}
            studentsLoading={studentsLoading}
            studentsError={studentsError}
            selectedStudent={selectedStudent}
            onStudentSelect={handleStudentSelect}
            isExam={isExam}
            examId={examId}
        />
    );
};

export default TeacherTasksContainer;
