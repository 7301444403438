import React, { useState } from 'react';
import TasksLayout from '../components/TasksLayout';
import TeacherTasksList from './TeacherTasksList';
import TeacherTaskContent from './TeacherTaskContent';

const TeacherTasksView = ({
                              tasksList,
                              selectedTaskId,
                              setSelectedTaskId,
                              taskDetails,
                              taskLoading,
                              taskError,
                              theme,
                              setTheme,
                              editorContent,
                              setEditorContent,
                              outputMode,
                              setOutputMode,
                              codeOutput,
                              onRunCode,
                              onResetCode,
                              runCounter,
                              showConsole,
                              setShowConsole,
                              consoleLogCount,
                              setConsoleLogCount,
                              consoleOutput,
                              onConsoleMessage,
                              courseStudents,
                              studentsLoading,
                              studentsError,
                              selectedStudent,
                              onStudentSelect,
                              isExam,
                              examId,
                              attemptId,
                          }) => {
    const [fontSize, setFontSize] = useState(15);

    const navbarProps = {
        theme,
        setTheme,
        onRunCode,
        outputMode,
        setOutputMode,
        onResetCode,
        fontSize,
        onFontSizeChange: setFontSize,
        userRole: 'teacher',
        canEdit: !selectedStudent,
        showConsole,
        setShowConsole,
        consoleLogCount,
        taskDetails,
        taskId: selectedTaskId,
        editorContent,
        courseStudents,
        selectedStudent,
        onStudentSelect,
        studentsLoading,
        studentsError,
        isExam,
        examId,
        attemptId,
        studentId: selectedStudent?.id
    };

    const editorProps = {
        theme,
        editorContent,
        setEditorContent,
        taskId: selectedTaskId,
        language: taskDetails?.language,
        fontSize,
        canEdit: !selectedStudent,
        userRole: 'teacher',
        taskType: taskDetails?.type,
        readOnly: !!selectedStudent,
        isExam
    };

    const outputProps = {
        output: codeOutput,
        outputMode,
        runCounter,
        taskId: selectedTaskId,
        showConsole,
        consoleOutput,
        onConsoleMessage,
        setConsoleLogCount,
        isExam
    };

    return (
        <TasksLayout
            LeftSidebar={
                <TeacherTasksList
                    tasksList={tasksList}
                    selectedTaskId={selectedTaskId}
                    onSelect={setSelectedTaskId}
                />
            }
            RightSidebar={
                <TeacherTaskContent
                    loading={taskLoading}
                    error={taskError}
                    task={taskDetails}
                    isExam={isExam}
                    examId={examId}
                    selectedStudent={selectedStudent}
                    attemptId={attemptId}
                />
            }
            navbarProps={navbarProps}
            editorProps={editorProps}
            outputProps={outputProps}
            error={taskError}
        />
    );
};

export default TeacherTasksView;
