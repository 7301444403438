import { BaseRunner } from './BaseRunner';

export class BrowserRunner extends BaseRunner {
    constructor(darkMode = false) {
        super();
        this.darkMode = darkMode;
    }

    getWrappedCode(code, currentTheme) {
        return `
        <!DOCTYPE html>
        <html ${this.darkMode ? 'data-bs-theme="dark"' : ''}>
        <head>
            <style>
                body {
                    background-color: ${currentTheme.iframe.background};
                    color: ${currentTheme.pre.text};
                    margin: 0;
                    padding: 8px;
                    transition: background-color 0.3s ease, color 0.3s ease;
                }
            </style>
            <script>
                const originalConsole = {
                    log: console.log,
                    error: console.error,
                    warn: console.warn,
                    info: console.info,
                    table: console.table
                };
    
                console.log = function(...args) {
                    originalConsole.log.apply(console, args);
                    window.parent.postMessage({
                        type: 'console',
                        level: 'log',
                        content: args.map(arg => 
                            typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                        ).join(' ')
                    }, '*');
                };
    
                console.error = function(...args) {
                    originalConsole.error.apply(console, args);
                    window.parent.postMessage({
                        type: 'console',
                        level: 'error',
                        content: args.map(arg => 
                            typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                        ).join(' ')
                    }, '*');
                };
    
                console.warn = function(...args) {
                    originalConsole.warn.apply(console, args);
                    window.parent.postMessage({
                        type: 'console',
                        level: 'warn',
                        content: args.map(arg => 
                            typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                        ).join(' ')
                    }, '*');
                };
    
                console.info = function(...args) {
                    originalConsole.info.apply(console, args);
                    window.parent.postMessage({
                        type: 'console',
                        level: 'info',
                        content: args.map(arg => 
                            typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                        ).join(' ')
                    }, '*');
                };
    
                console.table = function(data, columns) {
                    originalConsole.table.apply(console, arguments);
                    
                    let tableData = [];
                    let headers = [];
                    
                    if (Array.isArray(data)) {
                        if (data.length > 0) {
                            if (typeof data[0] === 'object' && data[0] !== null) {
                                headers = columns || Object.keys(data[0]);
                                tableData = data.map(item => {
                                    let row = {};
                                    headers.forEach(header => {
                                        row[header] = item[header];
                                    });
                                    return row;
                                });
                            } else {
                                headers = ['(index)', 'Value'];
                                tableData = data.map((item, index) => ({
                                    '(index)': index,
                                    'Value': item
                                }));
                            }
                        }
                    } else if (typeof data === 'object' && data !== null) {
                        headers = ['Key', 'Value'];
                        tableData = Object.entries(data).map(([key, value]) => ({
                            'Key': key,
                            'Value': typeof value === 'object' ? JSON.stringify(value) : value
                        }));
                    }
    
                    window.parent.postMessage({
                        type: 'console',
                        level: 'table',
                        isTable: true,
                        tableData: {
                            headers,
                            rows: tableData
                        },
                        content: 'Table Output'
                    }, '*');
                };
    
                window.onerror = function(msg, url, line, col, error) {
                    window.parent.postMessage({
                        type: 'console',
                        level: 'error',
                        content: \`Error: \${msg} (line: \${line}, column: \${col})\`
                    }, '*');
                    return false;
                };
    
                window.addEventListener('unhandledrejection', function(event) {
                    window.parent.postMessage({
                        type: 'console',
                        level: 'error',
                        content: \`Unhandled Promise rejection: \${event.reason}\`
                    }, '*');
                });
            </script>
        </head>
        <body>
            ${code}
        </body>
        </html>
        `;
    }

    async run(code, options = {}) {
        return {
            success: true,
            output: code,
            mode: 'html'
        };
    }
}
