import { useState, useEffect } from 'react';
import usePost from './usePost';
import useApiConfig from './useApiConfig';

const useAuth = () => {
    const { response, loading, error, postData } = usePost();
    const { fetchConfig } = useApiConfig();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authError, setAuthError] = useState(null);

    // Sprawdź stan autentykacji przy montowaniu
    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        setIsAuthenticated(!!token);
    }, []);

    // Logowanie
    const login = async (username, password) => {
        try {
            setAuthError(null);
            await postData('LOGIN', { username, password });
        } catch (err) {
            setAuthError(err.response?.data?.message || 'Invalid username or password');
        }
    };

    // Wylogowanie
    const logout = async () => {
        try {
            const refreshToken = sessionStorage.getItem('refresh_token');
            await postData('LOGOUT', { refresh_token: refreshToken });
        } catch (err) {
            console.error('Logout error:', err);
        } finally {
            clearSession();
        }
    };

    // Obsługa pomyślnego logowania
    useEffect(() => {
        const handleSuccessfulLogin = async (loginResponse) => {
            try {
                // Zapisz dane sesji
                const { access_token, refresh_token, username, role } = loginResponse;
                saveSession({ access_token, refresh_token, username, role });

                // Pobierz konfigurację API
                await fetchConfig(access_token);

                setIsAuthenticated(true);
                setAuthError(null);
            } catch (err) {
                setAuthError('Error loading application configuration');
                clearSession();
            }
        };

        if (response?.access_token) {
            handleSuccessfulLogin(response);
        }
    }, [response, fetchConfig]);

    // Pomocnicze funkcje do zarządzania sesją
    const saveSession = ({ access_token, refresh_token, username, role }) => {
        sessionStorage.setItem('access_token', access_token);
        sessionStorage.setItem('refresh_token', refresh_token);
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('role', role);
    };

    const clearSession = () => {
        sessionStorage.clear();
        setIsAuthenticated(false);
        setAuthError(null);
    };

    return {
        login,
        logout,
        isAuthenticated,
        loading,
        error: authError || error
    };
};

export default useAuth;
