import React, { useState } from 'react';
import { useFetch, usePost } from '../../hooks';
import { useApi } from '../../context/ApiContext';
import UsersList from './UsersList';

const UsersListContainer = ({
                                filters = {},
                                formConfig = {},
                                viewConfig = {}
                            }) => {
    const [showForm, setShowForm] = useState(false);
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    const { endpoints } = useApi();

    // Określamy endpoint na podstawie filtrów
    const getEndpoint = () => {
        if (filters.schoolId) {
            if (filters.role === 'teacher') return 'ADMIN_SCHOOL_TEACHERS';
            if (filters.role === 'student') return 'ADMIN_SCHOOL_STUDENTS';
            return 'ADMIN_SCHOOL_USERS';
        }
        if (filters.departmentId) return 'ADMIN_DEPARTMENT_USERS';
        return 'ADMIN_USERS';
    };

    // Parametry dla endpointu
    const getEndpointParams = () => {
        if (filters.schoolId) return [filters.schoolId];
        if (filters.departmentId) return [filters.departmentId];
        return undefined;
    };

    // Podstawowe dane
    const { data, loading, error, fetchData } = useFetch(
        getEndpoint(),
        getEndpointParams()
    );

    // Dane formularza - pobierane tylko gdy formularz jest otwarty
    const { data: statusesData } = useFetch('STATUSES', undefined, { enabled: showForm });
    const { data: rolesData, loading: rolesLoading } = useFetch('ADMIN_ROLES', undefined, { enabled: showForm });
    const { data: departmentsData, loading: departmentsLoading } = useFetch(
        'ADMIN_SCHOOL_DEPARTMENTS',
        formConfig.schoolId ? [formConfig.schoolId] : undefined,
        { enabled: showForm && !!formConfig.schoolId }
    );

    const { postData, response: actionResponse, error: actionError, loading: actionLoading } = usePost();

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleDelete = async (userId) => {
        try {
            const method = endpoints?.ADMIN_USER_DELETE?.method || 'DELETE';
            await postData('ADMIN_USER_DELETE', null, [userId], method);
            fetchData();
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    };

    const handleSave = async (formData, userId = null) => {
        try {
            const dataToSubmit = {
                ...formData,
                role_id: parseInt(formData.role_id),
                school_id: formData.school_id ? parseInt(formData.school_id) : null,
                department_ids: formData.department_ids.map(id => parseInt(id))
            };

            if (userId) {
                const updateMethod = endpoints?.ADMIN_USER_UPDATE?.method || 'PUT';
                await postData('ADMIN_USER_UPDATE', dataToSubmit, [userId], updateMethod);
            } else {
                const addMethod = endpoints?.ADMIN_USER_ADD?.method || 'POST';
                await postData('ADMIN_USER_ADD', dataToSubmit, null, addMethod);
            }
            fetchData();
            setShowForm(false);
        } catch (error) {
            console.error('Failed to save user:', error);
        }
    };

    // Sortowanie danych
    const sortData = (users) => {
        if (!users) return [];

        return [...users].sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (aValue === undefined || aValue === null) aValue = '';
            if (bValue === undefined || bValue === null) bValue = '';

            if (sortConfig.key === 'departments') {
                aValue = (a.departments || []).map(d => d.name).join(', ');
                bValue = (b.departments || []).map(d => d.name).join(', ');
            }

            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const sortedData = sortData(data?.users || data);
    const userStatusOptions = statusesData?.user?.map(status => ({
        id: status.value,
        name: status.label
    })) || [];

    return (
        <UsersList
            filters={filters}
            formConfig={formConfig}
            viewConfig={viewConfig}
            data={sortedData}
            loading={loading || actionLoading || (showForm && (rolesLoading || departmentsLoading))}
            error={error}
            deleteError={actionError}
            deleteResponse={actionResponse}
            onDelete={handleDelete}
            onSave={handleSave}
            roles={rolesData?.roles || []}
            departments={departmentsData?.departments || []}
            statusOptions={userStatusOptions}
            sortConfig={sortConfig}
            onSort={handleSort}
            showForm={showForm}
            setShowForm={setShowForm}
        />
    );
};

export default UsersListContainer;
