import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MemoryRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { ApiProvider } from './context/ApiContext';
import PageLayout                   from './components/PageLayout';
import MenuContainer                from './components/MenuContainer';
import LoginPageContainer           from './auth/LoginPageContainer';
import Component0                   from './example/Component0';
import UsersContainer               from "./admin/Users/UsersContainer";
import DepartmentsContainer         from "./admin/Departments/DepartmentsContainer";
import DepartmentUsersContainer     from "./admin/Departments/DepartmentUsersContainer";
import SchoolsContainer             from "./admin/Schools/SchoolsContainer";
import SchoolDetailsContainer       from "./admin/Schools/SchoolDetailsContainer";
import SchoolDepartmentsContainer   from "./admin/Schools/SchoolDepartmentsContainer";
import UserInfo                     from './profile/UserInfo';
import StudentExamsContainer        from "./features/exams/student/StudentExamsContainer";
import StudentExamTasksContainer    from "./features/exams/student/StudentExamTasksContainer";
import TeacherExamsContainer        from "./features/exams/teacher/TeacherExamsContainer";

import AdminCoursesContainer        from './features/courses/admin/AdminCoursesContainer';
import TeacherCoursesContainer      from './features/courses/teacher/TeacherCoursesContainer';
import StudentCoursesContainer      from "./features/courses/student/StudentCoursesContainer";

import StudentTopicsContainer       from "./features/topics/student/StudentTopicsContainer";
import TeacherTopicsContainer       from "./features/topics/teacher/TeacherTopicsContainer";
import AdminTopicsContainer         from "./features/topics/admin/AdminTopicsContainer";

import StudentTasksContainer        from "./features/tasks/student/StudentTasksContainer";
import TeacherTasksContainer        from "./features/tasks/teacher/TeacherTasksContainer";
import AdminTasksContainer          from "./features/tasks/admin/AdminTasksContainer";

function AppContent() {
    const isLoggedIn = () => sessionStorage.getItem('access_token') != null;
    const { i18n } = useTranslation();

    useEffect(() => {
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);

    return (
        <PageLayout menu={isLoggedIn() && <MenuContainer />}>
            <Routes>
                {/* Niezalogowani widzą tylko formularz logowania */}
                <Route path="/" element={!isLoggedIn() ? <LoginPageContainer /> : <Component0 />} />

                {/* Zabezpieczenie tras dostępnych tylko dla zalogowanych użytkowników */}
                {isLoggedIn() ? (
                    <>
                        <Route path="/admin/users-table" element={<UsersContainer />} />
                        <Route path="/admin/departments-table" element={<DepartmentsContainer />} />
                        <Route path="/admin/departments/:departmentId/users" element={<DepartmentUsersContainer />} />

                        <Route path="/admin/schools-table" element={<SchoolsContainer />} />
                        <Route path="/admin/schools/:schoolId" element={<SchoolDetailsContainer />} />
                        <Route path="/admin/schools/:schoolId/departments" element={<SchoolDepartmentsContainer />} />
                        <Route path="/admin/schools/:schoolId/departments/:departmentId/users" element={<DepartmentUsersContainer />} />

                        <Route path="/admin/courses" element={<AdminCoursesContainer />} />
                        <Route path="/admin/topics/:courseId" element={<AdminTopicsContainer />} />
                        <Route path="/admin/tasks/:topicId" element={<AdminTasksContainer />} />

                        <Route path="/teacher/courses" element={<TeacherCoursesContainer />} />
                        <Route path="/teacher/topics/:courseId" element={<TeacherTopicsContainer />} />
                        <Route path="/teacher/tasks/:topicId" element={<TeacherTasksContainer />} />

                        <Route path="/student/courses" element={<StudentCoursesContainer />} />
                        <Route path="/student/topics/:courseId" element={<StudentTopicsContainer />} />
                        <Route path="/student/tasks/:topicId" element={<StudentTasksContainer />} />
                        <Route path="/student/exam/:examId/attempt/:attemptId" element={<StudentExamTasksContainer />} />

                        <Route path="/user-info" element={<UserInfo />} />

                        {/* Dla studentów */}
                        <Route path="/student/exams" element={<StudentExamsContainer />} />

                        {/* Dla nauczycieli */}
                        <Route path="/teacher/exams" element={<TeacherExamsContainer />} />
                    </>
                ) : (
                    <Route path="*" element={<Navigate replace to="/" />} />
                )}
            </Routes>
        </PageLayout>
    );
}

function App() {
    return (
        <ApiProvider>
            <ThemeProvider>
                <MemoryRouter
                    initialEntries={['/']}
                    initialIndex={0}
                    future={{
                        v7_startTransition: true,
                        v7_relativeSplatPath: true
                    }}
                >
                    <AppContent />
                </MemoryRouter>
            </ThemeProvider>
        </ApiProvider>
    );
}

export default App;
