import React, { useState } from 'react';
import styled from 'styled-components';
import PythonCanvas from './pythonWorld/PythonCanvas';
import TaskContentModal from './TaskContentModal';

const ContentWrapper = styled.div`
    padding: 1rem;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

const TopPanel = styled.div`
    flex: 1;
    margin-bottom: 1rem;
    min-height: 200px;
    border: 1px solid var(--bs-border-color);
    border-radius: 0.375rem;
    padding: 1rem;
    overflow-y: auto;
`;

const BottomPanel = styled.div`
    flex: ${props => props.$hasTopPanel ? 1 : 'auto'};
    overflow-y: auto;
`;

const TaskHeader = styled.div`
    margin-bottom: 1.5rem;
`;

const TaskTitle = styled.h4`
    margin-bottom: 0.5rem;
    color: var(--bs-heading-color);
`;

const TaskMeta = styled.div`
    font-size: 0.9rem;
    color: var(--bs-secondary);
    margin-bottom: 1rem;
`;

const TaskSection = styled.div`
    margin-bottom: 1.5rem;
    cursor: pointer;

    h5 {
        color: var(--bs-heading-color);
        margin-bottom: 0.75rem;
    }

    p {
        color: var(--bs-body-color);
        white-space: pre-wrap;
    }
`;

const TimerWrapper = styled.div`
    margin-bottom: 1rem;
`;

const TasksContentLayout = ({
                                title,
                                language,
                                description,
                                content,
                                form,
                                isEditing,
                                examTimer,
                                children
                            }) => {
    const [showModal, setShowModal] = useState(false);
    const isPython = language?.toLowerCase() === 'python';

    if (isEditing && form) {
        return form;
    }

    return (
        <ContentWrapper>
            {examTimer && (
                <TimerWrapper>
                    {examTimer}
                </TimerWrapper>
            )}

            {isPython && (
                <TopPanel>
                    <PythonCanvas />
                </TopPanel>
            )}

            <BottomPanel $hasTopPanel={isPython}>
                <TaskHeader>
                    <TaskTitle>{title}</TaskTitle>
                    {language && (
                        <TaskMeta>
                            <strong>Język:</strong> {language}
                        </TaskMeta>
                    )}
                </TaskHeader>

                {description && (
                    <TaskSection onClick={() => setShowModal(true)}>
                        <h5>Opis:</h5>
                        <p>{description}</p>
                    </TaskSection>
                )}

                {content && (
                    <TaskSection onClick={() => setShowModal(true)}>
                        <h5>Treść:</h5>
                        <p>{content}</p>
                    </TaskSection>
                )}

                {children}
            </BottomPanel>

            <TaskContentModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={title}
                language={language}
                description={description}
                content={content}
            />
        </ContentWrapper>
    );
};

export default TasksContentLayout;
