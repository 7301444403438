import React, { useState, useEffect } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ExamEndModal from './ExamEndModal';

const TimerContainer = styled.div`
    position: relative;
`;

const TimerAlert = styled(Alert)`
    margin-bottom: 0;
    padding-right: 100px;
`;

const EndExamButton = styled(Button)`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        opacity: 1;
    }
`;

const ExamTimer = ({ startTime,
                       timeLimit,
                       onTimeEnd,
                       onExamEnd }) => {
    const { t } = useTranslation();
    const [timeLeft, setTimeLeft] = useState(null);
    const [alertVariant, setAlertVariant] = useState('info');
    const [hasEnded, setHasEnded] = useState(false);
    const [showEndModal, setShowEndModal] = useState(false);

    const handleEndExam = () => {
        setShowEndModal(true);
    };

    const handleConfirmEnd = () => {
        setShowEndModal(false);
        if (typeof onExamEnd === 'function') {
            onExamEnd();
        }
    };

    useEffect(() => {
        if (!startTime || !timeLimit || hasEnded) return;

        const calculateTimeLeft = () => {
            // Usuwamy dodawanie 'Z' bo data już jest w UTC
            const start = new Date(startTime);
            const now = new Date();
            const timeLimitMs = timeLimit * 60 * 1000;
            const endTime = new Date(start.getTime() + timeLimitMs);
            const difference = endTime - now;

            // Możemy dodać debug info do weryfikacji
            console.log('Debug dates:', {
                startTime,
                parsedStart: start.toISOString(),
                currentTime: now.toISOString(),
                endTime: endTime.toISOString(),
                difference
            });

            if (difference <= 0) {
                const timeElapsedMinutes = (now - start) / (1000 * 60);
                console.log('Time elapsed (minutes):', timeElapsedMinutes);

                if (timeElapsedMinutes >= timeLimit && !hasEnded) {
                    setHasEnded(true);
                    onTimeEnd?.();
                }
                return { hours: 0, minutes: 0, seconds: 0 };
            }

            return {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        };

        const timer = setInterval(() => {
            const timeRemaining = calculateTimeLeft();
            setTimeLeft(timeRemaining);

            const totalMinutesLeft = timeRemaining.hours * 60 + timeRemaining.minutes;
            if (totalMinutesLeft <= 5) {
                setAlertVariant('danger');
            } else if (totalMinutesLeft <= 15) {
                setAlertVariant('warning');
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [startTime, timeLimit, onTimeEnd, hasEnded]);

    if (!timeLeft) return null;

    const formatTime = (time) => time.toString().padStart(2, '0');

    return (
        <>
            <TimerContainer>
                <TimerAlert variant={alertVariant}>
                    {timeLimit ? (
                        <>
                            {t('timeRemaining')}: {formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}
                        </>
                    ) : (
                        t('noTimeLimit')
                    )}
                    <EndExamButton
                        variant="outline-secondary"
                        size="sm"
                        onClick={handleEndExam}
                        title={t('endExamTooltip')}
                    >
                        {t('endExam')}
                    </EndExamButton>
                </TimerAlert>
            </TimerContainer>

            <ExamEndModal
                show={showEndModal}
                onClose={() => setShowEndModal(false)}
                onConfirm={handleConfirmEnd}
            />
        </>
    );
};

export default ExamTimer;
