import React from 'react';
import OutputManager from './output/OutputManager';

const TasksCodeOutput = ({
                             output,
                             loading,
                             error,
                             outputMode,
                             runCounter,
                             taskId,
                             showConsole,
                             consoleOutput,
                             onConsoleMessage,
                             setConsoleLogCount
                         }) => {
    return (
        <OutputManager
            output={output}
            loading={loading}
            error={error}
            outputMode={outputMode}
            runCounter={runCounter}
            taskId={taskId}
            showConsole={showConsole}
            consoleOutput={consoleOutput}
            onConsoleMessage={onConsoleMessage}
            setConsoleLogCount={setConsoleLogCount}
        />
    );
};

export default TasksCodeOutput;
