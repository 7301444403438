import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';
import { useApi } from '../context/ApiContext';

const usePost = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { endpoints } = useApi();

    const postData = useCallback(async (endpointKey, data = null, params = [], method = 'POST') => {
        setLoading(true);
        setError(null);

        try {
            let url;
            if (endpointKey === 'LOGIN') {
                url = `${config.apiUrl}/auth/login`;
            } else if (endpointKey === 'LOGOUT') {
                url = `${config.apiUrl}/auth/logout`;
            } else if (endpointKey === 'REFRESH_TOKEN') {
                url = `${config.apiUrl}/auth/refresh`;
            } else {
                // Pobierz ścieżkę z endpointów
                let path = endpoints[endpointKey].path;

                // Formatuj ścieżkę z parametrami
                if (params) {
                    const paramsArray = Array.isArray(params) ? params : [params];
                    paramsArray.forEach((param) => {
                        path = path.replace('%s', param);
                    });
                }

                url = `${config.apiUrl}/${path}`;
            }

            const result = await axios({
                method: method,
                url,
                data,
                headers: endpointKey !== 'LOGIN' ? {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                } : {}
            });

            setResponse(result.data);
            return result.data;
        } catch (err) {
            const errorMessage = err.response?.data?.message || err.message;
            setError(errorMessage);
            if (err.response?.status === 401 && endpointKey !== 'LOGIN') {
                sessionStorage.removeItem('access_token');
                navigate('/');
            }
            throw err;
        } finally {
            setLoading(false);
        }
    }, [endpoints, navigate]);

    return { response, loading, error, postData };
};

export default usePost;
