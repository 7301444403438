import React, { useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import TasksContentLayout from '../components/TasksContentLayout';
import { useFetch } from '../../../hooks';
import styled from "styled-components";

const NoTaskMessage = styled.p`
    padding: 1rem;
`;

const TaskForm = ({ task, onSubmit, onCancel, tasksList }) => {
    const { data: languages, loading: languagesLoading } = useFetch('LANGUAGES');
    const defaultLanguage = tasksList?.topic_language;

    // Funkcja znajdująca następny numer dla typu zadania
    const getNextNumber = (type) => {
        const typePrefix = type === 'example' ? 'Przykład ' : 'Zadanie ';
        const items = (tasksList?.tasks || []).filter(t => t.type === type);
        const numbers = items
            .map(t => {
                const match = t.name.match(new RegExp(`^${typePrefix}(\\d+)`));
                return match ? parseInt(match[1]) : 0;
            })
            .filter(n => !isNaN(n));

        return numbers.length > 0 ? Math.max(...numbers) + 1 : 1;
    };

    // Inicjalizacja z domyślną nazwą i językiem z topica
    const [formData, setFormData] = React.useState({
        name: `Zadanie ${getNextNumber('task')}`,
        description: '',
        content: '',
        type: 'task',
        language: task?.language || defaultLanguage || '',
        ...task
    });

    // Ustaw język z topica tylko jeśli nie ma języka z zadania
    useEffect(() => {
        if (!formData.language && defaultLanguage) {
            setFormData(prev => ({ ...prev, language: defaultLanguage }));
        }
    }, [defaultLanguage]);

    // Ustaw język z dostępnych języków tylko jeśli nie ma ani języka z zadania, ani z topica
    useEffect(() => {
        const shouldSetDefaultLanguage = languages?.length > 0
            && !formData.language
            && !task?.language
            && !defaultLanguage;

        if (shouldSetDefaultLanguage) {
            setFormData(prev => ({ ...prev, language: languages[0].value }));
        }
    }, [languages, formData.language, task?.language, defaultLanguage]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    if (languagesLoading) {
        return <Spinner animation="border" />;
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Label column={true}>Nazwa</Form.Label>
                <Form.Control
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    required
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label column={true}>Typ</Form.Label>
                <Form.Select
                    value={formData.type}
                    onChange={(e) => {
                        const newType = e.target.value;
                        const newName = `${newType === 'example' ? 'Przykład' : 'Zadanie'} ${getNextNumber(newType)}`;
                        setFormData(prev => ({ ...prev, type: newType, name: newName }));
                    }}
                >
                    <option value="task">Zadanie</option>
                    <option value="example">Przykład</option>
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label column={true}>Język</Form.Label>
                <Form.Select
                    value={formData.language}
                    onChange={(e) => setFormData(prev => ({ ...prev, language: e.target.value }))}
                >
                    {languages?.map(lang => (
                        <option key={lang.value} value={lang.value}>
                            {lang.label}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label column={true}>Opis</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.description || ''}
                    onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                />
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label column={true}>Treść</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    value={formData.content || ''}
                    onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
                />
            </Form.Group>

            <div className="d-flex gap-2">
                <Button type="submit" variant="primary">
                    {task ? 'Zapisz zmiany' : 'Dodaj zadanie'}
                </Button>
                <Button type="button" variant="secondary" onClick={onCancel}>
                    Anuluj
                </Button>
            </div>
        </Form>
    );
};

const AdminTaskContent = ({
                              loading,
                              error,
                              task,
                              tasksList,
                              isEditing,
                              isAdding,
                              onSave,
                              onCancel
                          }) => {
    if (isEditing || isAdding) {
        return (
            <TasksContentLayout
                form={
                    <TaskForm
                        task={isEditing ? task : null}
                        tasksList={tasksList}
                        onSubmit={onSave}
                        onCancel={onCancel}
                    />
                }
                isEditing={true}
            />
        );
    }

    if (error) {
        return <div className="text-danger">{error}</div>;
    }

    if (loading || !task) {
        return (
            <TasksContentLayout>
                <NoTaskMessage>Wybierz zadanie z listy lub dodaj nowe.</NoTaskMessage>
            </TasksContentLayout>
        );
    }

    return (
        <TasksContentLayout
            title={task.name}
            language={task.language}
            description={task.description}
            content={task.content}
            isEditing={false}
        />
    );
};

export default AdminTaskContent;
