import { BaseRunner } from './BaseRunner';
import worldManager from '../pythonWorld/initWorldManager';

let globalPyodidePromise = null;

const PYTHON_INTERFACE = `
import js
from pyodide.ffi import create_proxy, to_js
import math

class SpriteMeta(type):
    def __call__(cls, *args, **kwargs):
        obj = cls.__new__(cls)
        # Najpierw inicjalizujemy pola kontrolne
        obj._in_world = False
        obj._sprite_id = None
        
        if cls is not Sprite:  # Jeśli to klasa pochodna
            obj.__init__(*args, **kwargs)  # Inicjalizacja klasy pochodnej
            # Uzupełniamy brakujące atrybuty z bazowej klasy Sprite
            for attr, default_value in Sprite._default_values.items():
                if not hasattr(obj, attr):
                    setattr(obj, attr, default_value)
        else:  # Jeśli to bezpośrednio Sprite
            Sprite.__init__(obj)  # Inicjalizacja bazowa
            
        obj._initial_state = {
            name: getattr(obj, name)
            for name in ['x', 'y', 'direction', 'size', 'image', 'color']
        }
        return obj

class Sprite(metaclass=SpriteMeta):
    _default_values = {
        'size': 50,
        'image': 0,
        'color': (255, 0, 0),
        'direction': 0,
        'x': 0,
        'y': 0,
    }
    
    def __init__(self):
        # Najpierw inicjalizujemy pola kontrolne (na wszelki wypadek, choć są już ustawione w metaklasie)
        self._in_world = False
        self._sprite_id = None
        # Teraz reszta pól
        self._x = 0
        self._y = 0
        self._direction = 0
        self._size = 50
        self._image = 0
        self._color = (255, 0, 0)
        
    @property
    def x(self):
        return self._x
        
    @x.setter
    def x(self, value):
        self._x = value
        self._update_state()
        
    @property
    def y(self):
        return self._y
        
    @y.setter
    def y(self, value):
        self._y = value
        self._update_state()
        
    @property
    def direction(self):
        return self._direction
        
    @direction.setter
    def direction(self, value):
        self._direction = value % 360  # Normalizacja kąta
        self._update_state()
        
    @property
    def size(self):
        return self._size
        
    @size.setter
    def size(self, value):
        self._size = value
        self._update_state()
        
    @property
    def image(self):
        return self._image
        
    @image.setter
    def image(self, value):
        if value in [0, 1, 2]:
            self._image = value
            self._update_state()
            
    @property
    def color(self):
        return self._color
        
    @color.setter
    def color(self, value):
        if isinstance(value, (tuple, list)) and len(value) == 3:
            self._color = tuple(value)
            self._update_state()

    def reset_state(self):
        if hasattr(self, '_initial_state'):
            for name, value in self._initial_state.items():
                setattr(self, name, value)
        
    def _get_state(self):
        return {
            'x': self.x,
            'y': self.y,
            'size': self.size,
            'image': self.image,
            'color': f'rgb{self.color}',
            'direction': self.direction
        }
        
    def _update_state(self):
        if self._in_world and self._sprite_id is not None:
            state = self._get_state()
            js.window.worldManager.updateSprite(self._sprite_id, to_js(state))
        
    def move(self, distance):
        angle_rad = math.radians(self.direction)
        self.x += distance * math.sin(angle_rad)
        self.y += distance * math.cos(angle_rad)
        
    def set_color(self, r, g, b):
        self.color = (r, g, b)
        
    def set_size(self, size):
        self.size = size
        
    def set_image(self, image_type):
        if image_type in [0, 1, 2]:
            self.image = image_type
        
    def turn(self, angle):
        self.direction = (self.direction + angle) % 360

class World:
    def __init__(self):
        self._manager = js.window.worldManager
        self._next_id = 0
        self._sprites = {}
        print("World initialized")

    def reset(self):
        self._manager.reset()
        self._next_id = 0
        self._sprites.clear()
        print("World reset")
        
    def add(self, sprite):
        print(f"Adding sprite to world, sprites before: {self._sprites}")
        sprite.reset_state()
        sprite._sprite_id = str(self._next_id)
        sprite._in_world = True
        self._next_id += 1
        
        state = to_js(sprite._get_state())
        state['id'] = sprite._sprite_id
        
        self._sprites[sprite._sprite_id] = sprite
        self._manager.addSprite(state)
        print(f"Sprite added, sprites after: {self._sprites}")
        
    def remove(self, sprite):
        if sprite._in_world:
            self._manager.removeSprite(sprite._sprite_id)
            if sprite._sprite_id in self._sprites:
                del self._sprites[sprite._sprite_id]
            sprite._in_world = False
            sprite._sprite_id = None

    def update_all(self, *args):
        """Aktualizuje wszystkie duszki"""
        try:
            for sprite_id, sprite in self._sprites.items():
                try:
                    if hasattr(sprite, 'update'):
                        sprite.update()
                except Exception as e:
                    print(f"Error updating sprite {sprite_id}: {e}")
        except Exception as e:
            print(f"Error in update_all: {e}")
        
    def start(self):
        print("Starting world")
        js.window.pythonWorldUpdate = create_proxy(self.update_all)
        print("Python world update registered")
        self._manager.start()
        print("Manager started")

    def stop(self):
        print("Stopping world")
        if hasattr(js.window, 'pythonWorldUpdate'):
            js.window.pythonWorldUpdate.destroy()
            del js.window.pythonWorldUpdate
        self._manager.stop()

world = World()
`;

export class PythonBrowserRunner extends BaseRunner {
    constructor(darkMode = false) {
        super();
        this.darkMode = darkMode;

        if (!globalPyodidePromise) {
            globalPyodidePromise = this.initialize();
        }
        this.pyodideReady = globalPyodidePromise;
    }

    async initialize() {
        if (!window.loadPyodide) {
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/pyodide/v0.24.1/full/pyodide.js';
            document.head.appendChild(script);
            await new Promise(resolve => script.onload = resolve);
        }
        const pyodide = await window.loadPyodide();

        // Inicjalizacja interfejsu Python
        await pyodide.runPython(PYTHON_INTERFACE);

        return pyodide;
    }

    async run(code) {
        const pyodide = await this.pyodideReady;

        try {
            pyodide.runPython(`
            import sys
            from io import StringIO
            sys.stdout = StringIO()
            sys.stderr = StringIO()
            world.reset()
        `);

            await pyodide.runPythonAsync(code);

            const stdout = pyodide.runPython("sys.stdout.getvalue()");
            const stderr = pyodide.runPython("sys.stderr.getvalue()");

            if (stderr) {
                return {
                    success: false,
                    output: stderr,
                    mode: 'text'
                };
            }

            return {
                success: true,
                output: stdout,
                mode: 'text'
            };

        } catch (error) {
            return {
                success: false,
                output: `Error: ${error.message}`,
                mode: 'text'
            };
        }
    }
}
