import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch, usePost } from '../../hooks';
import DepartmentUsersView from './DepartmentUsersView';

const DepartmentUsersContainer = () => {
    const { departmentId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Pobranie schoolId z URL jeśli istnieje
    const schoolId = location.pathname.includes('/schools/')
        ? location.pathname.split('/schools/')[1].split('/')[0]
        : null;

    // Fetche
    const { data, loading, error, fetchData } = useFetch('ADMIN_DEPARTMENT_USERS', [departmentId]);
    const { data: rolesData, loading: rolesLoading } = useFetch('ADMIN_ROLES');
    const { postData, response: actionResponse, error: actionError, loading: actionLoading } = usePost();
    const { data: schoolData } = useFetch(schoolId && 'ADMIN_SCHOOL', schoolId ? [schoolId] : null);
    const { data: departmentData } = useFetch('ADMIN_DEPARTMENT', [departmentId]);

    // Stan sortowania
    const [sortConfig, setSortConfig] = useState({
        key: 'id',
        direction: 'asc'
    });

    // Stany modala i formularza
    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        name: '',
        surname: '',
        role_id: '',
        status: 'active',
        is_super: false,
        school_id: schoolId,
        department_ids: [departmentId]
    });

    // Funkcja sortująca
    const sortData = (users, sortKey, direction) => {
        if (!users) return [];

        return [...users].sort((a, b) => {
            let aValue = a[sortKey];
            let bValue = b[sortKey];

            if (sortKey === 'active') {
                return direction === 'asc' ?
                    Number(aValue) - Number(bValue) :
                    Number(bValue) - Number(aValue);
            }

            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }

            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleSort = (key) => {
        setSortConfig(prevConfig => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
        }));
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setFormData({
            username: '',
            password: '',
            email: '',
            name: '',
            surname: '',
            role_id: '',
            status: 'active',
            is_super: false,
            school_id: schoolId,
            department_ids: [departmentId]
        });
    };

    const handleShowForm = (user = null) => {
        if (user) {
            setFormData({
                username: user.username,
                email: user.email,
                name: user.name,
                surname: user.surname,
                role_id: user.role_id.toString(),
                status: user.status,
                is_super: user.is_super,
                school_id: schoolId,
                department_ids: [departmentId]
            });
            setSelectedUserId(user.id);
            setEditMode(true);
        } else {
            setFormData({
                username: '',
                password: '',
                email: '',
                name: '',
                surname: '',
                role_id: '',
                status: 'active',
                is_super: false,
                school_id: schoolId,
                department_ids: [departmentId]
            });
            setEditMode(false);
        }
        setShowForm(true);
    };

    const handleChange = (event) => {
        const { name, type, value, checked } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const dataToSubmit = {
                ...formData,
                role_id: parseInt(formData.role_id),
                school_id: parseInt(formData.school_id),
                department_ids: formData.department_ids.map(id => parseInt(id))
            };

            if (editMode) {
                await postData('ADMIN_USER_UPDATE', dataToSubmit, [selectedUserId]);
            } else {
                await postData('ADMIN_USER_ADD', dataToSubmit);
            }
            handleCloseForm();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas zapisywania:', error);
        }
    };

    const handleShowDelete = (userId) => {
        setSelectedUserId(userId);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setSelectedUserId(null);
    };

    const confirmDelete = async () => {
        try {
            await postData('ADMIN_USER_DELETE', null, [selectedUserId]);
            handleCloseDelete();
            fetchData();
        } catch (error) {
            console.error('Błąd podczas usuwania:', error);
        }
    };

    useEffect(() => {
        if (actionResponse) {
            fetchData();
        }
    }, [actionResponse, fetchData]);

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('confirmUserDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    const sortedUsers = sortData(data?.users, sortConfig.key, sortConfig.direction);

    const handleNavigateBack = () => {
        if (schoolId) {
            navigate(`/admin/schools/${schoolId}/departments`);
        } else {
            navigate('/admin/departments-table');
        }
    };

    // Przygotowanie pól formularza z uwzględnieniem wybranej szkoły i oddziału
    const formFields = [
        { name: 'username', type: 'text', label: 'username', required: true },
        { name: 'password', type: 'password', label: 'password', required: true, hideOnEdit: true },
        { name: 'email', type: 'email', label: 'email', required: true },
        { name: 'name', type: 'text', label: 'name', required: true },
        { name: 'surname', type: 'text', label: 'surname', required: true },
        {
            name: 'role_id',
            type: 'select',
            label: 'role',
            required: true,
            options: rolesData?.roles || [],
            placeholder: 'selectRole'
        },
        {
            name: 'status',
            type: 'select',
            label: 'status',
            required: true,
            options: [
                { id: 'active', name: t('active') },
                { id: 'inactive', name: t('inactive') }
            ]
        },
        {
            name: 'school_id',
            type: 'text',
            label: 'school',
            value: schoolData?.school?.name || '',
            disabled: true,
            hidden: !schoolId
        },
        {
            name: 'department_ids',
            type: 'text',
            label: 'department',
            value: departmentData?.department?.name || '',
            disabled: true
        }
    ];

    return (
        <DepartmentUsersView
            loading={loading || rolesLoading || actionLoading}
            error={error}
            actionError={actionError}
            actionResponse={actionResponse}
            departmentName={data?.department?.name}
            users={sortedUsers}
            showDelete={showDelete}
            showForm={showForm}
            editMode={editMode}
            formData={formData}
            roles={rolesData?.roles || []}
            sortConfig={sortConfig}
            deleteModalTexts={deleteModalTexts}
            onSort={handleSort}
            onShowForm={handleShowForm}
            onCloseForm={handleCloseForm}
            onShowDelete={handleShowDelete}
            onCloseDelete={handleCloseDelete}
            onConfirmDelete={confirmDelete}
            onSubmit={handleSubmit}
            onChange={handleChange}
            onNavigateBack={handleNavigateBack}
            departmentId={departmentId}
            formFields={formFields}
        />
    );
};

export default DepartmentUsersContainer;
