import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import AdminTasksView from './AdminTasksView';
import styled from 'styled-components';
import { RunnerFactory } from '../components/runners/RunnerFactory';

const ViewContainer = styled.div`
    height: calc(100vh - 120px);
    position: relative;
    z-index: 1;
`;

const AdminTasksContainer = () => {
    const { topicId } = useParams();
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);
    const [consoleOutput, setConsoleOutput] = useState([]);

    const {
        data: tasksList,
        loading: tasksLoading,
        error: tasksError,
        fetchData: refreshTasksList
    } = useFetch(
        'ADMIN_TOPIC_TASKS',
        topicId ? [topicId] : null
    );

    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId ? 'ADMIN_TASK_DETAILS' : null,
        selectedTaskId ? [selectedTaskId] : null
    );

    const { postData } = usePost();

    const handleConsoleMessage = React.useCallback((message) => {
        setConsoleOutput(prev => {
            const newOutput = [...prev, {
                level: message.level || 'log',
                content: message.content,
                isTable: message.isTable,
                tableData: message.tableData
            }];
            setConsoleLogCount(newOutput.length);
            return newOutput;
        });
    }, []);

    const clearConsole = React.useCallback(() => {
        setConsoleOutput([]);
        setConsoleLogCount(0);
    }, []);

    const handleRunCode = async () => {
        clearConsole();

        try {
            if (taskDetails?.language === 'javascript') {
                // Dla JavaScript zostawiamy obecną logikę
                setCodeOutput(editorContent);
                setOutputMode('html');
                setRunCounter(prev => prev + 1);
            } else if (taskDetails?.language === 'python') {
                // Dla Pythona używamy dedykowanego runnera
                const runner = RunnerFactory.createRunner('python', {
                    darkMode
                });

                const result = await runner.run(editorContent, {
                    language: 'python'
                });

                setCodeOutput(result.output);
                setOutputMode(result.mode || 'text');
                setRunCounter(prev => prev + 1);
            } else {
                // Dla pozostałych języków używamy endpointu
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskDetails?.language
                });

                if (response.output) {
                    setCodeOutput(response.output);
                    setOutputMode('text');
                } else if (response.error) {
                    setCodeOutput(`Błąd: ${response.error}`);
                    setOutputMode('text');
                } else {
                    setCodeOutput('Brak wyniku lub wystąpił nieznany błąd.');
                    setOutputMode('text');
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (err) {
            console.error('Błąd wykonania kodu:', err);
            setCodeOutput(`Wystąpił błąd podczas wykonywania kodu: ${err.message}`);
            setOutputMode('text');
            setRunCounter(prev => prev + 1);
        }
    };

    const handleBackToTopics = () => {
        const courseId = tasksList?.course_id || localStorage.getItem('lastCourseId');
        if (courseId) {
            //navigate(`/admin/topics/${courseId}`);
        } else {
            //navigate('/admin/courses');
        }
    };

    const handleTaskSelect = (taskId) => {
        if (taskId !== null) {
            setSelectedTaskId(taskId);
            setIsEditing(false);
            setIsAdding(false);
            setCodeOutput('');
            clearConsole();
        }
    };

    const handleSave = async (formData) => {
        try {
            if (isAdding) {
                await postData('ADMIN_TASK_ADD', {
                    ...formData,
                    topic_id: topicId
                });
            } else {
                await postData('ADMIN_TASK_UPDATE', formData, [selectedTaskId], 'PUT');
            }

            setIsEditing(false);
            setIsAdding(false);
            refreshTasksList();
            if (selectedTaskId) {
                refreshTaskDetails();
            }
        } catch (error) {
            console.error('Error saving task:', error);
        }
    };

    React.useEffect(() => {
        if (taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails]);

    React.useEffect(() => {
        if (selectedTaskId === null) {
            setEditorContent('');
        }
    }, [selectedTaskId]);

    React.useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    React.useEffect(() => {
        if (topicId) {
            setSelectedTaskId(null);
            setEditorContent('');
            setCodeOutput('');
            clearConsole();
            setIsEditing(false);
            setIsAdding(false);
            refreshTasksList();
        }
    }, [topicId, refreshTasksList, clearConsole]);

    return (
        <ViewContainer>
            <AdminTasksView
                tasksList={tasksList}
                tasksLoading={tasksLoading}
                tasksError={tasksError}
                selectedTaskId={selectedTaskId}
                setSelectedTaskId={handleTaskSelect}
                taskDetails={taskDetails}
                taskLoading={taskLoading}
                taskError={taskError}
                theme={theme}
                setTheme={setTheme}
                editorContent={editorContent}
                setEditorContent={setEditorContent}
                outputMode={outputMode}
                setOutputMode={setOutputMode}
                codeOutput={codeOutput}
                onRunCode={handleRunCode}
                runCounter={runCounter}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isAdding={isAdding}
                setIsAdding={setIsAdding}
                onSave={handleSave}
                showConsole={showConsole}
                setShowConsole={setShowConsole}
                consoleLogCount={consoleLogCount}
                consoleOutput={consoleOutput}
                onConsoleMessage={handleConsoleMessage}
                onRefreshTasks={refreshTasksList}
                onBackToTopics={handleBackToTopics}
            />
        </ViewContainer>
    );
};

export default AdminTasksContainer;
