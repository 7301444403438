import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config/config';
import { useApi } from '../context/ApiContext';

const useFetch = (endpointPath, params, enabled = true) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { endpoints, loading: apiLoading } = useApi();

    const fetchData = useCallback(async () => {
        if (!enabled || !endpointPath) {
            return;
        }

        if (!endpoints) {
            setError("API configuration not available");
            return;
        }

        setLoading(true);
        try {
            const endpoint = endpoints[endpointPath];

            if (!endpoint) {
                throw new Error(`Endpoint ${endpointPath} not found`);
            }

            let path = endpoint.path;
            if (params) {
                const paramsArray = Array.isArray(params) ? params : [params];
                paramsArray.forEach((param) => {
                    path = path.replace('%s', param);
                });
            }

            const url = `${config.apiUrl}/${path}`;

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });

            setData(response.data);
            setError(null);
        } catch (err) {
            setError(err.message);
            if (err.response?.status === 401) {
                sessionStorage.removeItem('access_token');
                navigate('/');
            }
        } finally {
            setLoading(false);
        }
    }, [endpointPath, params && JSON.stringify(params), endpoints, apiLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        data,
        loading: loading || apiLoading,
        error,
        fetchData
    };
};

export default useFetch;
