export class WorldManager {
    constructor() {
        this.sprites = new Map();
        this.isRunning = false;
        this.animationFrameId = null;
        this.canvasUpdate = null;
        //console.log('WorldManager constructor');
    }

    setCanvasUpdate(updateFn) {
        console.log('Setting canvas update function');
        this.canvasUpdate = updateFn;
    }

    reset() {
        console.log('Resetting world');
        this.sprites.clear();  // Używamy clear() dla Map
        this._updateCanvas();
        this.stop();
    }

    addSprite(state) {
        const id = String(state.get('id') || state.id);  // Obsługa obu formatów
        console.log('Adding sprite:', state);
        this.sprites.set(id, state instanceof Map ? state : new Map(Object.entries(state)));
        this._updateCanvas();
    }

    removeSprite(spriteId) {
        const id = String(spriteId);
        console.log('Removing sprite:', id);
        this.sprites.delete(id);  // Używamy delete() dla Map
        this._updateCanvas();
    }

    updateSprite(spriteId, newState) {
        const id = String(spriteId);
        console.log('Updating sprite:', id, newState);
        if (this.sprites.has(id)) {
            const currentState = this.sprites.get(id);
            // Konwertujemy newState na Map jeśli nie jest
            const updateState = newState instanceof Map ? newState : new Map(Object.entries(newState));
            // Aktualizujemy wartości
            for (const [key, value] of updateState.entries()) {
                currentState.set(key, value);
            }
            console.log('Updated state:', currentState);
            this._updateCanvas();
        } else {
            console.warn('Sprite not found:', id);
        }
    }

    _updateCanvas() {
        if (this.canvasUpdate) {
            // Konwertujemy Map na obiekty dla renderowania
            const currentSprites = Array.from(this.sprites.values())
                .map(sprite => sprite instanceof Map ? Object.fromEntries(sprite) : sprite);
            console.log('Updating canvas with sprites:', currentSprites);
            this.canvasUpdate(currentSprites);
        } else {
            console.warn('No canvas update function set');
        }
    }

    start() {
        console.log('WorldManager: Starting animation');
        if (!this.isRunning) {
            this.isRunning = true;
            this.update();
        }
    }

    stop() {
        console.log('WorldManager: Stopping animation');
        this.isRunning = false;
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }
    }

    update() {
        if (!this.isRunning) return;

        console.log("Update cycle, pythonWorldUpdate available:", !!window.pythonWorldUpdate);
        if (window.pythonWorldUpdate) {
            console.log("Calling Python update");
            try {
                window.pythonWorldUpdate();
            } catch (e) {
                console.error("Error in update:", e);
            }
        }

        this._updateCanvas();
        this.animationFrameId = requestAnimationFrame(() => this.update());
    }
}
