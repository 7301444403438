import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config/config';

const ApiContext = createContext(null);

// Funkcja pomocnicza do spłaszczania struktury endpointów
const flattenEndpoints = (obj, prefix = '') => {

    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value && typeof value === 'object' && !value.method) {
            return { ...acc, ...flattenEndpoints(value, `${prefix}${key}_`) };
        }
        const newKey = `${prefix}${key}`;
        return { ...acc, [newKey]: value };
    }, {});
};

export const ApiProvider = ({ children }) => {
    const [endpoints, setEndpoints] = useState(null);
    const [loading, setLoading] = useState(true);

    const setFlattenedEndpoints = (data) => {
        const flatEndpoints = flattenEndpoints(data);
        setEndpoints(flatEndpoints);
    };

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (token) {
            axios.get(`${config.apiUrl}/config`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    setFlattenedEndpoints(response.data);
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        sessionStorage.clear();
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);

    const value = {
        endpoints,
        setEndpoints: setFlattenedEndpoints,
        loading
    };

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider');
    }
    return context;
};
