import React from 'react';
import { ListGroup, Spinner, Alert } from 'react-bootstrap';
import styled from 'styled-components';

const ListContainer = styled.div`
    margin-bottom: 10px;
`;

// Zmieniamy sposób stylowania bazując na data-atrybutach
const StyledListGroupItem = styled(ListGroup.Item).attrs(props => ({
    'data-type': props.$itemType,
    'data-selected': props.$isSelected,
    'data-clickable': !props.$disabled,
}))`
    background-color: var(--bs-body-bg);
    color: ${props => props['data-type'] === 'task' ? 'var(--bs-primary)' : 'var(--bs-secondary)'};
    border-left: 4px solid ${props => props['data-type'] === 'task' ? 'var(--bs-primary)' : 'var(--bs-secondary)'};
    transition: all 0.2s ease-in-out;
    padding: 0;

    &:hover:not([data-selected="true"]) {
        background-color: ${props => props['data-type'] === 'task'
                ? 'var(--bs-primary-bg-subtle)'
                : 'var(--bs-secondary-bg-subtle)'};
    }

    &[data-selected="true"] {
        background-color: var(--bs-primary-bg-subtle);
        border-left-color: var(--bs-primary);
    }
`;

const TaskContent = styled.div.attrs(props => ({
    'data-disabled': props.disabled
}))`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: ${props => props['data-disabled'] ? 'not-allowed' : 'pointer'};
    opacity: ${props => props['data-disabled'] ? 0.7 : 1};
    width: 100%;
`;

const TaskMainContent = styled.div`
    display: flex;
    align-items: center;
    min-width: 0;
`;

const TaskIcon = styled.span`
    font-size: 1.1em;
    flex-shrink: 0;
    margin-right: 0.5rem;
`;

const TaskName = styled.span.attrs(props => ({
    style: {
        fontWeight: props.selected ? '600' : 'normal'
    }
}))`
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const TasksListLayout = ({
                             loading,
                             error,
                             items,
                             selectedId,
                             onSelect,
                             renderControls,
                             bottomContent,
                             disabled
                         }) => {
    if (loading) {
        return <Spinner animation="border" role="status" />;
    }

    if (error || !Array.isArray(items)) {
        return <Alert variant="danger">
            {error || 'Błąd: Nieprawidłowy format danych'}
        </Alert>;
    }

    const getTaskIcon = (type) => type === 'task' ? '✏️' : '📖';

    const handleClick = (itemId, e) => {
        // Sprawdzamy, czy kliknięcie nie było na kontrolkach
        if (e.target.closest('.task-controls')) {
            return;
        }

        if (!disabled && onSelect) {
            onSelect(itemId);
        }
    };

    return (
        <>
            <ListContainer>
                <ListGroup>
                    {items.map((item) => (
                        <StyledListGroupItem
                            key={item.id}
                            itemType={item.type}
                            $isSelected={item.id === selectedId}
                            disabled={disabled}
                            as="div"
                            className="mb-1"
                            role="button"
                            tabIndex={0}
                        >
                            <TaskContent
                                onClick={(e) => handleClick(item.id, e)}
                                disabled={disabled}
                            >
                                <TaskMainContent>
                                    <TaskIcon>{getTaskIcon(item.type)}</TaskIcon>
                                    <TaskName selected={item.id === selectedId}>
                                        {item.name}
                                    </TaskName>
                                </TaskMainContent>
                                {!disabled && renderControls && (
                                    <ControlsWrapper className="task-controls">
                                        {renderControls(item)}
                                    </ControlsWrapper>
                                )}
                            </TaskContent>
                        </StyledListGroupItem>
                    ))}
                </ListGroup>
            </ListContainer>
            {bottomContent}
        </>
    );
};

export default TasksListLayout;
