import React from 'react';
import ConsoleOutput from './ConsoleOutput';
import HtmlPreview from './HtmlPreview';
import TextOutput from './TextOutput';
import { useTheme } from '../../../../context/ThemeContext';

const OutputManager = ({
                           output,
                           loading,
                           error,
                           outputMode,
                           runCounter,
                           taskId,
                           showConsole,
                           consoleOutput,
                           onConsoleMessage
                       }) => {
    const { darkMode } = useTheme();

    React.useEffect(() => {
        const handleMessage = (event) => {
            if (event.data.type === 'console') {
                onConsoleMessage(event.data);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [onConsoleMessage]);

    const currentTheme = {
        dark: {
            console: {
                background: '#1e1e1e',
                text: '#fff',
                border: '#333',
                headerText: '#888',
                table: {
                    headerBg: '#444',
                    headerText: '#fff',
                    cellBg: '#333',
                    cellText: '#fff',
                    border: '#666'
                },
                logs: {
                    error: '#ff4444',
                    warn: '#ffb86c',
                    info: '#63c5da',
                    log: '#fff'
                }
            },
            pre: {
                background: '#1e1e1e',
                text: '#fff'
            },
            iframe: {
                background: '#1e1e1e'
            }
        },
        light: {
            console: {
                background: '#f8f9fa',
                text: '#212529',
                border: '#dee2e6',
                headerText: '#6c757d',
                table: {
                    headerBg: '#e9ecef',
                    headerText: '#212529',
                    cellBg: '#ffffff',
                    cellText: '#212529',
                    border: '#dee2e6'
                },
                logs: {
                    error: '#dc3545',
                    warn: '#ffc107',
                    info: '#0dcaf0',
                    log: '#212529'
                }
            },
            pre: {
                background: '#ffffff',
                text: '#212529'
            },
            iframe: {
                background: '#ffffff'
            }
        }
    }[darkMode ? 'dark' : 'light'];

    if (loading) {
        return <p>Wykonywanie kodu...</p>;
    }

    if (error) {
        return <p style={{ color: currentTheme.console.logs.error }}>Błąd: {error}</p>;
    }

    return (
        <div style={{
            height: '33%',
            overflow: 'hidden',
            backgroundColor: currentTheme.console.background,
            color: currentTheme.console.text,
            transition: 'background-color 0.3s ease, color 0.3s ease'
        }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flex: showConsole ? 3 : 4 }}>
                    {outputMode === 'text' ? (
                        <TextOutput
                            output={output}
                            theme={currentTheme}
                        />
                    ) : (
                        <HtmlPreview
                            output={output}
                            theme={currentTheme}
                            darkMode={darkMode}
                            runCounter={runCounter}
                        />
                    )}
                </div>
                {showConsole && (
                    <ConsoleOutput
                        consoleOutput={consoleOutput}
                        theme={currentTheme}
                    />
                )}
            </div>
        </div>
    );
};

export default OutputManager;
