import React, { useState } from 'react';
import { Container, Alert } from 'react-bootstrap';
import styled from 'styled-components';
import TasksTopNavbar from './TasksTopNavbar';
import TasksCodeEditor from './TasksCodeEditor';
import TasksCodeOutput from './TasksCodeOutput';

const MainContainer = styled(Container)`
    height: calc(100vh - var(--menu-height));
    padding: 0;
    display: flex;
    overflow: hidden;

    ${() => {
        if (!document.documentElement.style.getPropertyValue('--menu-height')) {
            document.documentElement.style.setProperty('--menu-height', '120px');
        }
        return '';
    }}
`;

const ResizableCol = styled.div`
    position: relative;
    height: 100%;
    overflow-y: hidden;
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    width: ${props => props.$width}px;
    min-width: ${props => props.$minWidth}px;
    max-width: ${props => props.$maxWidth}px;
`;

const ResizeHandle = styled.div`
    position: absolute;
    ${props => props.$position}: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: var(--bs-border-color);
    cursor: col-resize;

    &:hover {
        background: var(--bs-primary);
    }
`;

const MainContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 400px;
    overflow: hidden;
`;

const AlertContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
`;

const ContentWrapper = styled.div`
    height: 100%;
    overflow: auto;
    padding: 0.5rem;
`;

const TasksLayout = ({
                         LeftSidebar,
                         RightSidebar,
                         navbarProps,
                         editorProps,
                         outputProps,
                         error
                     }) => {
    const [leftWidth, setLeftWidth] = useState(200);
    const [rightWidth, setRightWidth] = useState(300);
    const [isResizingLeft, setIsResizingLeft] = useState(false);
    const [isResizingRight, setIsResizingRight] = useState(false);

    const handleMouseMove = React.useCallback((e) => {
        if (isResizingLeft) {
            const newWidth = e.clientX;
            if (newWidth >= 100 && newWidth <= 300) {
                setLeftWidth(newWidth);
            }
        } else if (isResizingRight) {
            const windowWidth = window.innerWidth;
            const newWidth = windowWidth - e.clientX;
            if (newWidth >= 200 && newWidth <= 500) {
                setRightWidth(newWidth);
            }
        }
    }, [isResizingLeft, isResizingRight]);

    const handleMouseUp = React.useCallback(() => {
        setIsResizingLeft(false);
        setIsResizingRight(false);
    }, []);

    const handleMouseDown = (position) => (e) => {
        if (position === 'left') {
            setIsResizingLeft(true);
        } else {
            setIsResizingRight(true);
        }
        e.preventDefault();
    };

    React.useEffect(() => {
        if (isResizingLeft || isResizingRight) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizingLeft, isResizingRight, handleMouseMove, handleMouseUp]);

    React.useEffect(() => {
        const updateMenuHeight = () => {
            const menuElement = document.querySelector('.menu-container');
            if (menuElement) {
                const menuHeight = menuElement.offsetHeight;
                document.documentElement.style.setProperty('--menu-height', `${menuHeight}px`);
            }
        };

        updateMenuHeight();
        window.addEventListener('resize', updateMenuHeight);

        return () => window.removeEventListener('resize', updateMenuHeight);
    }, []);

    if (error) {
        return (
            <AlertContainer>
                <Alert variant="danger">{error}</Alert>
            </AlertContainer>
        );
    }

    return (
        <MainContainer fluid>
            <ResizableCol
                $width={leftWidth}
                $minWidth={200}
                $maxWidth={400}
            >
                <ContentWrapper>
                    {LeftSidebar}
                </ContentWrapper>
                <ResizeHandle
                    $position="right"
                    onMouseDown={handleMouseDown('left')}
                />
            </ResizableCol>

            <MainContent>
                <TasksTopNavbar {...navbarProps} />
                <TasksCodeEditor {...editorProps} />
                <TasksCodeOutput {...outputProps} />
            </MainContent>

            <ResizableCol
                $width={rightWidth}
                $minWidth={200}
                $maxWidth={500}
            >
                <ResizeHandle
                    $position="left"
                    onMouseDown={handleMouseDown('right')}
                />
                <ContentWrapper>
                    {RightSidebar}
                </ContentWrapper>
            </ResizableCol>
        </MainContainer>
    );
};

export default TasksLayout;
