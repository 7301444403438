import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch, usePost } from '../../../hooks';
import { useTheme } from '../../../context/ThemeContext';
import { useSnapshotSender } from '../../../hooks/useSnapshotSender';
import { RunnerFactory } from '../components/runners/RunnerFactory';
import StudentTasksView from './StudentTasksView';

const StudentTasksContainer = () => {
    const { topicId } = useParams();
    const { darkMode } = useTheme();
    const [theme, setTheme] = useState(() => darkMode ? 'monokai' : 'github');
    const [editorContent, setEditorContent] = useState('');
    const [selectedTaskId, setSelectedTaskId] = useState(null);
    const [outputMode, setOutputMode] = useState('html');
    const [codeOutput, setCodeOutput] = useState('');
    const [runCounter, setRunCounter] = useState(0);
    const [showConsole, setShowConsole] = useState(false);
    const [consoleLogCount, setConsoleLogCount] = useState(0);
    const [consoleOutput, setConsoleOutput] = useState([]);

    const { data: tasksList, fetchData: refreshTasksList } = useFetch(
        'STUDENT_TOPIC_INSTANCE_TASKS',
        topicId ? [topicId] : undefined
    );

    const {
        data: taskDetails,
        loading: taskLoading,
        error: taskError,
        fetchData: refreshTaskDetails
    } = useFetch(
        selectedTaskId ? 'STUDENT_TASK_INSTANCE_DETAILS' : null,
        selectedTaskId ? [selectedTaskId] : undefined
    );

    const { postData } = usePost();

    useSnapshotSender(selectedTaskId, editorContent, false, taskDetails?.type, false);

    const handleConsoleMessage = React.useCallback((message) => {
        setConsoleOutput(prev => {
            const newOutput = [...prev, {
                level: message.level || 'log',
                content: message.content,
                isTable: message.isTable,
                tableData: message.tableData
            }];
            setConsoleLogCount(newOutput.length);
            return newOutput;
        });
    }, []);

    const clearConsole = React.useCallback(() => {
        setConsoleOutput([]);
        setConsoleLogCount(0);
    }, []);

    const handleResetCode = async () => {
        if (!selectedTaskId) return;

        try {
            const response = await postData(
                'STUDENT_TASK_INSTANCE_ORIGINAL',
                null,
                [selectedTaskId]
            );

            if (response?.code !== undefined) {
                setEditorContent(response.code);
                setCodeOutput('');
                clearConsole();
            }
        } catch (error) {
            console.error('Błąd podczas resetowania kodu:', error);
            setCodeOutput('Błąd: Nie udało się zresetować kodu');
        }
    };

    const handleRunCode = async () => {
        clearConsole();

        try {
            if (taskDetails?.language === 'javascript') {
                // Dla JavaScript zostawiamy obecną logikę
                setCodeOutput(editorContent);
                setOutputMode('html');
                setRunCounter(prev => prev + 1);
            } else if (taskDetails?.language === 'python') {
                // Dla Pythona używamy dedykowanego runnera
                const runner = RunnerFactory.createRunner('python', {
                    darkMode
                });

                const result = await runner.run(editorContent, {
                    language: 'python'
                });

                setCodeOutput(result.output);
                setOutputMode(result.mode || 'text');
                setRunCounter(prev => prev + 1);
            } else {
                // Dla pozostałych języków używamy endpointu
                const response = await postData('EXECUTE_CODE', {
                    code: editorContent,
                    language: taskDetails?.language
                });

                if (response.output) {
                    setCodeOutput(response.output);
                    setOutputMode('text');
                } else if (response.error) {
                    setCodeOutput(`Błąd: ${response.error}`);
                    setOutputMode('text');
                } else {
                    setCodeOutput('Brak wyniku lub wystąpił nieznany błąd.');
                    setOutputMode('text');
                }
                setRunCounter(prev => prev + 1);
            }
        } catch (err) {
            console.error('Błąd wykonania kodu:', err);
            setCodeOutput(`Wystąpił błąd podczas wykonywania kodu: ${err.message}`);
            setOutputMode('text');
            setRunCounter(prev => prev + 1);
        }
    };

    const handleTaskSelect = (taskId) => {
        if (taskId === selectedTaskId) {
            return;
        }
        setSelectedTaskId(taskId);
        setCodeOutput('');
        clearConsole();
    };

    React.useEffect(() => {
        if (selectedTaskId) {
            refreshTaskDetails();
        }
    }, [selectedTaskId, refreshTaskDetails]);

    React.useEffect(() => {
        if (taskDetails && 'code' in taskDetails) {
            setEditorContent(taskDetails.code || '');
        }
    }, [taskDetails]);

    React.useEffect(() => {
        setTheme(darkMode ? 'monokai' : 'github');
    }, [darkMode]);

    React.useEffect(() => {
        if (topicId) {
            setSelectedTaskId(null);
            setEditorContent('');
            setCodeOutput('');
            clearConsole();
            refreshTasksList();
        }
    }, [topicId, refreshTasksList, clearConsole]);

    return (
        <StudentTasksView
            tasksList={tasksList}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={handleTaskSelect}
            taskDetails={taskDetails}
            taskLoading={taskLoading}
            taskError={taskError}
            theme={theme}
            setTheme={setTheme}
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            outputMode={outputMode}
            setOutputMode={setOutputMode}
            codeOutput={codeOutput}
            onRunCode={handleRunCode}
            onResetCode={handleResetCode}
            runCounter={runCounter}
            showConsole={showConsole}
            setShowConsole={setShowConsole}
            consoleLogCount={consoleLogCount}
            consoleOutput={consoleOutput}
            onConsoleMessage={handleConsoleMessage}
            setConsoleLogCount={setConsoleLogCount}
        />
    );
};

export default StudentTasksContainer;
