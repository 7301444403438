import React from 'react';

const TextOutput = ({ output, theme }) => {
        const colorMap = {
                '30': 'black',
                '31': 'red',
                '32': 'green',
                '33': 'yellow',
                '34': 'blue',
                '35': 'magenta',
                '36': 'cyan',
                '37': 'white',
                '90': '#888888',
                '91': '#ff0000',
                '92': '#00ff00',
                '93': '#ffff00',
                '94': '#0000ff',
                '95': '#ff00ff',
                '96': '#00ffff',
                '97': '#ffffff'
        };

        const styleMap = {
                '0': {}, // reset
                '1': { fontWeight: 'bold' },
                '2': { opacity: 0.7 }, // dim
                '3': { fontStyle: 'italic' },
                '4': { textDecoration: 'underline' },
                '5': { animation: 'blink 1s step-end infinite' },
                '7': { filter: 'invert(100%)' }, // reverse
                '8': { opacity: 0 }, // hidden
                '9': { textDecoration: 'line-through' }
        };

        const parseAnsi = (text) => {
                // Zmodyfikowany regex do łapania kodów ANSI z wieloma parametrami
                const parts = text.split(/(\u001b\[[\d;]+m)/);
                let currentStyles = {};

                return parts.map((part, index) => {
                        // Zmieniony regex do wyciągania wszystkich kodów
                        const styleMatch = part.match(/\u001b\[([\d;]+)m/);

                        if (styleMatch) {
                                const codes = styleMatch[1].split(';');

                                // Przetwarzamy każdy kod w sekwencji
                                codes.forEach(code => {
                                        if (code === '0') {
                                                // Reset wszystkich stylów
                                                currentStyles = {};
                                        } else if (colorMap[code]) {
                                                currentStyles.color = colorMap[code];
                                        } else if (styleMap[code]) {
                                                currentStyles = { ...currentStyles, ...styleMap[code] };
                                        }
                                });
                                return null;
                        } else if (part.trim() === '') {
                                return part;
                        } else {
                                return (
                                    <span
                                        key={index}
                                        style={{
                                                ...currentStyles,
                                                color: currentStyles.color || theme.pre.text
                                        }}
                                    >
                        {part}
                    </span>
                                );
                        }
                });
        };

        return (
            <pre style={{
                    margin: 0,
                    padding: 10,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    overflow: 'auto',
                    height: '100%',
                    backgroundColor: theme.pre.background,
                    color: theme.pre.text,
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    '@keyframes blink': {
                            '0%, 100%': {
                                    opacity: 1
                            },
                            '50%': {
                                    opacity: 0
                            }
                    }
            }}>
            {parseAnsi(output)}
        </pre>
        );
};

export default TextOutput;
