import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Container, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UsersListContainer from '../Users/UsersListContainer';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';
import { schoolFormConfig } from '../../config/formConfigs';

const SchoolDetailsView = ({
                               loading,
                               error,
                               school,
                               onEdit,
                               showEditModal,
                               handleCloseEditModal,
                               handleSubmit,
                               showTeachersModal,
                               handleShowTeachersModal,
                               handleCloseTeachersModal,
                               showStudentsModal,
                               handleShowStudentsModal,
                               handleCloseStudentsModal,
                               showDepartmentsModal,
                               handleShowDepartmentsModal,
                               handleCloseDepartmentsModal
                           }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(schoolFormConfig.defaultValues);

    useEffect(() => {
        if (school) {
            setFormData({
                name: school.name || '',
                code: school.code || '',
                description: school.description || '',
                address: school.address || '',
                school_year: school.school_year || '',
                capacity: school.capacity || '',
                phone: school.phone || '',
                email: school.email || '',
                status: school.status || 'ACTIVE'
            });
        }
    }, [school]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleSubmit(formData);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!school) return null;

    const basicInfo = [
        { label: 'schoolCode', value: school.code },
        { label: 'schoolYear', value: school.school_year },
        { label: 'status', value: school.status },
        { label: 'email', value: school.email || '-' },
        { label: 'phone', value: school.phone || '-' },
        { label: 'address', value: school.address || '-' },
        { label: 'capacity', value: school.capacity || '-' }
    ];

    const stats = [
        {
            label: 'departmentsCount',
            value: school.departments_count || 0,
            bgColor: 'bg-primary bg-opacity-10',
            textColor: 'text-primary-emphasis'
        },
        {
            label: 'teachersCount',
            value: school.teacher_count || 0,
            bgColor: 'bg-success bg-opacity-10',
            textColor: 'text-success-emphasis'
        },
        {
            label: 'studentsCount',
            value: school.student_count || 0,
            bgColor: 'bg-info bg-opacity-10',
            textColor: 'text-info-emphasis'
        }
    ];

    const manageButtons = [
        {
            label: 'manageDepartments',
            variant: 'outline-primary',
            onClick: handleShowDepartmentsModal,
            modal: showDepartmentsModal,
            onClose: handleCloseDepartmentsModal,
            props: {}
        },
        {
            label: 'manageTeachers',
            variant: 'outline-primary',
            onClick: handleShowTeachersModal,
            modal: showTeachersModal,
            onClose: handleCloseTeachersModal,
            component: UsersListContainer,
            props: {
                role: 'teacher',
                allowedRoles: ['teacher']
            }
        },
        {
            label: 'manageStudents',
            variant: 'outline-primary',
            onClick: handleShowStudentsModal,
            modal: showStudentsModal,
            onClose: handleCloseStudentsModal,
            component: UsersListContainer,
            props: {
                role: 'student',
                allowedRoles: ['student']
            }
        }
    ];

    return (
        <Container fluid className="mt-4">
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                        <h2 className="mb-0">{school.name}</h2>
                        <small className="text-muted">{school.description || t('noDescription')}</small>
                    </div>
                    <div className="d-flex gap-2">
                        {manageButtons.map(button => (
                            <Button
                                key={button.label}
                                variant={button.variant}
                                onClick={button.onClick}
                            >
                                {t(button.label)}
                            </Button>
                        ))}
                        <Button variant="outline-primary" onClick={onEdit}>
                            {t('editSchool')}
                        </Button>
                    </div>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <h3 className="h5 mb-3">{t('basicInformation')}</h3>
                            <dl className="row">
                                {basicInfo.map(item => (
                                    <React.Fragment key={item.label}>
                                        <dt className="col-sm-4">{t(item.label)}</dt>
                                        <dd className="col-sm-8">{item.value}</dd>
                                    </React.Fragment>
                                ))}
                            </dl>
                        </Col>
                        <Col md={6}>
                            <h3 className="h5 mb-3">{t('statistics')}</h3>
                            <Row>
                                {stats.map(stat => (
                                    <Col key={stat.label} md={4}>
                                        <div className={`text-center p-3 rounded ${stat.bgColor}`}>
                                            <div className={stat.textColor}>
                                                <div className="fs-6">{t(stat.label)}</div>
                                                <div className="h3 mb-0 fw-bold">{stat.value}</div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <AdminEntityFormModal
                show={showEditModal}
                handleClose={handleCloseEditModal}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleFormSubmit}
                error={error}
                loading={loading}
                isEdit={true}
                {...schoolFormConfig}
            />

            {manageButtons.map(button => button.modal && button.component && (
                <Modal
                    key={button.label}
                    show={button.modal}
                    onHide={button.onClose}
                    dialogClassName="modal-90w"
                    centered
                    size="xl"
                >
                    <Modal.Header closeButton className="border-bottom py-2">
                        <Modal.Title>{t(button.label)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0" style={{
                        height: 'calc(90vh - 60px)', // zmniejszona wysokość o padding nagłówka
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <button.component
                            filters={{ schoolId: school.id, ...button.props }}
                            formConfig={{
                                schoolId: school.id,
                                hideSchoolField: true,
                                ...button.props
                            }}
                            viewConfig={{
                                title: button.label,
                                subtitle: school.name,
                                hideColumns: ['school'],
                                containerClassName: 'h-100 d-flex flex-column'
                            }}
                        />
                    </Modal.Body>
                </Modal>
            ))}
        </Container>
    );
};

export default SchoolDetailsView;
