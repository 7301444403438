import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import TasksLayout from '../components/TasksLayout';
import AdminTasksList from './AdminTasksList';
import AdminTaskContent from './AdminTaskContent';
import styled from 'styled-components';

const EditModeOverlay = styled.div`
    position: fixed;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: ${props => props.$show ? 'block' : 'none'};
`;

const EditModeContent = styled.div`
    position: relative;
    z-index: 1000;
    height: 100%;
`;

const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`;

const AdminTasksView = ({
                            tasksList,
                            tasksLoading,
                            tasksError,
                            selectedTaskId,
                            setSelectedTaskId,
                            taskDetails,
                            taskLoading,
                            taskError,
                            theme,
                            setTheme,
                            editorContent,
                            setEditorContent,
                            outputMode,
                            setOutputMode,
                            codeOutput,
                            onRunCode,
                            runCounter,
                            isEditing,
                            setIsEditing,
                            isAdding,
                            setIsAdding,
                            onSave,
                            showConsole,
                            setShowConsole,
                            consoleOutput,
                            onConsoleMessage,
                            consoleLogCount,
                            setConsoleLogCount,
                            onRefreshTasks,
                            onBackToTopics
                        }) => {
    const [fontSize, setFontSize] = useState(15);
    const [showNavigationWarning, setShowNavigationWarning] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    const handleNavigation = (action) => {
        if (isEditing || isAdding) {
            setShowNavigationWarning(true);
            setPendingNavigation(action);
            return false;
        }
        action();
        return true;
    };

    const handleContinueNavigation = () => {
        setShowNavigationWarning(false);
        if (pendingNavigation) {
            pendingNavigation();
            setPendingNavigation(null);
        }
    };

    const handleCancelNavigation = () => {
        setShowNavigationWarning(false);
        setPendingNavigation(null);
    };

    const handleEditClick = () => {
        setIsEditing(true);
        setIsAdding(false);
    };

    const handleAddClick = () => {
        //console.log('AdminTasksView - handleAddClick - before states');
        setIsEditing(false);
        setIsAdding(true);
        setSelectedTaskId(null);
        setEditorContent('');
        //console.log('AdminTasksView - handleAddClick - after states');
    };

    const wrappedSetSelectedTaskId = (taskId) => {
        handleNavigation(() => setSelectedTaskId(taskId));
    };

    const wrappedOnBackToTopics = () => {
        handleNavigation(onBackToTopics);
    };

    const handleSave = (formData) => {
        const updatedData = {
            ...formData,
            code: editorContent
        };
        onSave(updatedData);
    };

    const navbarProps = {
        theme,
        setTheme,
        onRunCode,
        outputMode,
        setOutputMode,
        fontSize,
        onFontSizeChange: setFontSize,
        isEditing,
        isAdding,
        onEditClick: handleEditClick,
        onSaveClick: handleSave,  // Zmiana tutaj z onSave na handleSave
        onAddClick: handleAddClick,
        userRole: 'admin',
        canEdit: true,
        showConsole,
        setShowConsole,
        consoleLogCount,
        taskDetails,
        onBack: wrappedOnBackToTopics,
        disabled: isEditing || isAdding
    };

    const editorProps = {
        theme,
        editorContent,
        setEditorContent,
        taskId: selectedTaskId,
        language: taskDetails?.language,
        fontSize,
        isEditing: isEditing || isAdding,
        canEdit: true,
        userRole: 'admin'
    };

    const outputProps = {
        output: codeOutput,
        outputMode,
        runCounter,
        taskId: selectedTaskId,
        showConsole,
        consoleOutput,
        onConsoleMessage,
        setConsoleLogCount
    };

    //console.log('AdminTasksView - render, isAdding:', isAdding, 'isEditing:', isEditing);

    return (
        <ContentWrapper>
            <EditModeOverlay $show={isEditing || isAdding} />
            <EditModeContent>
                <TasksLayout
                    LeftSidebar={
                        <AdminTasksList
                            tasksList={tasksList?.tasks || []}
                            loading={tasksLoading}
                            error={tasksError}
                            selectedTaskId={selectedTaskId}
                            onSelect={wrappedSetSelectedTaskId}
                            onRefresh={onRefreshTasks}
                            onAddClick={() => {
                                handleNavigation(() => {
                                    handleAddClick();
                                });
                            }}
                            disabled={isEditing || isAdding}
                        />
                    }
                    RightSidebar={
                        <AdminTaskContent
                            loading={taskLoading}
                            error={taskError}
                            task={taskDetails}
                            tasksList={tasksList}
                            isEditing={isEditing}
                            isAdding={isAdding}
                            onSave={handleSave}
                            onCancel={() => {
                                setIsEditing(false);
                                setIsAdding(false);
                            }}
                        />
                    }
                    navbarProps={navbarProps}
                    editorProps={editorProps}
                    outputProps={outputProps}
                    error={taskError}
                />
            </EditModeContent>

            <Modal show={showNavigationWarning} onHide={handleCancelNavigation}>
                <Modal.Header closeButton>
                    <Modal.Title>Niezapisane zmiany</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Masz niezapisane zmiany. Czy chcesz kontynuować? Wszystkie zmiany zostaną utracone.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelNavigation}>
                        Zostań i zapisz
                    </Button>
                    <Button variant="danger" onClick={handleContinueNavigation}>
                        Porzuć zmiany
                    </Button>
                </Modal.Footer>
            </Modal>
        </ContentWrapper>
    );
};

export default AdminTasksView;
