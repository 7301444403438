import { BaseRunner } from './BaseRunner';
import axios from 'axios';
import { API_ENDPOINTS, buildApiUrl } from '../../../../config/endpoints';

export class ServerRunner extends BaseRunner {
    constructor(apiEndpoint) {
        super();
        this.apiEndpoint = apiEndpoint;
    }

    async run(code, options = {}) {
        try {
            const url = buildApiUrl(API_ENDPOINTS[this.apiEndpoint]);
            const response = await axios({
                method: 'POST',
                url,
                data: {
                    code,
                    language: options.language
                },
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('access_token')}`
                }
            });

            return {
                success: true,
                output: response.data.output || response.data.error,
                mode: 'text'
            };
        } catch (error) {
            return {
                success: false,
                output: `Error executing code: ${error.message}`,
                mode: 'text'
            };
        }
    }
}
