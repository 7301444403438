import React, { useState } from 'react';
import DeleteModal from '../../components/modals/DeleteModal';
import AdminDataTable from '../../components/generic/AdminDataTable';
import AdminEntityFormModal from '../../components/generic/AdminEntityFormModal';
import { useTranslation } from 'react-i18next';
import { schoolFormConfig } from '../../config/formConfigs';

const SchoolsList = ({
                         filters = {},
                         formConfig = {},
                         viewConfig = {},
                         data,
                         loading,
                         error,
                         onDelete,
                         onSave,
                         deleteError,
                         deleteResponse,
                         statusOptions,
                         sortConfig,
                         onSort,
                         onSchoolClick
                     }) => {
    const { t } = useTranslation();
    const [showDelete, setShowDelete] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [formData, setFormData] = useState(schoolFormConfig.defaultValues);

    const handleShowForm = (school = null) => {
        if (school) {
            setFormData({
                name: school.name,
                code: school.code,
                description: school.description,
                address: school.address,
                school_year: school.school_year,
                capacity: school.capacity,
                phone: school.phone,
                email: school.email,
                status: school.status
            });
            setSelectedSchool(school);
            setEditMode(true);
        } else {
            setFormData(schoolFormConfig.defaultValues);
            setEditMode(false);
        }
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setEditMode(false);
        setSelectedSchool(null);
    };

    const handleShowDelete = (school) => {
        setSelectedSchool(school);
        setShowDelete(true);
    };

    const handleCloseDelete = () => {
        setShowDelete(false);
        setSelectedSchool(null);
    };

    const handleConfirmDelete = () => {
        onDelete(selectedSchool.id);
        handleCloseDelete();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onSave(formData, editMode ? selectedSchool.id : null);
        handleCloseForm();
    };

    const handleChange = (event) => {
        const { name, value, type } = event.target;
        const newValue = type === 'number' ? (value === '' ? '' : parseInt(value, 10)) : value;

        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }));
    };

    // Konfiguracja kolumn
    const defaultColumns = [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'code', label: 'code', sortable: true }
    ];

    if (!viewConfig.hideColumns?.includes('description')) {
        defaultColumns.push({ key: 'description', label: 'description', sortable: true });
    }
    // ... reszta konfiguracji kolumn bez zmian

    const defaultActions = [
        { label: 'info', variant: 'info', onClick: onSchoolClick },
        { label: 'edit', variant: 'secondary', onClick: handleShowForm },
        { label: 'delete', variant: 'danger', onClick: handleShowDelete }
    ].filter(action => !viewConfig.hideActions?.includes(action.label));

    const deleteModalTexts = {
        title: t('confirmDeletion'),
        body: t('school_confirmDeletionMessage'),
        cancel: t('cancel'),
        confirm: t('delete'),
    };

    return (
        <>
            <AdminDataTable
                loading={loading}
                error={error}
                actionError={deleteError}
                actionResponse={deleteResponse}
                title={viewConfig.title || "schools"}
                subtitle={viewConfig.subtitle}
                data={data}
                columns={defaultColumns}
                actions={defaultActions}
                headerButtons={[
                    { label: 'addSchool', variant: 'primary', onClick: () => handleShowForm() }
                ]}
                sortConfig={sortConfig}
                onSort={onSort}
            />

            <AdminEntityFormModal
                show={showForm}
                handleClose={handleCloseForm}
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                error={error}
                loading={loading}
                isEdit={editMode}
                {...schoolFormConfig}
            />

            <DeleteModal
                show={showDelete}
                handleClose={handleCloseDelete}
                handleConfirm={handleConfirmDelete}
                texts={deleteModalTexts}
            />
        </>
    );
};

export default SchoolsList;
