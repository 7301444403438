import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
    .modal-dialog {
        max-width: 800px;
        width: 90%;
        margin: 1.75rem auto;
    }
`;

const ContentSection = styled.div`
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
`;

const SectionTitle = styled.h5`
    color: var(--bs-heading-color);
    margin-bottom: 1rem;
`;

const SectionContent = styled.p`
    color: var(--bs-body-color);
    white-space: pre-wrap;
    font-size: 1.1rem;
    line-height: 1.6;
`;

const TaskMeta = styled.div`
    font-size: 1rem;
    color: var(--bs-secondary);
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--bs-border-color);
`;

const TaskContentModal = ({ show, onHide, title, language, description, content }) => {
    return (
        <StyledModal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            contentClassName={document.documentElement.getAttribute('data-bs-theme') === 'dark' ? 'bg-dark' : ''}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {language && (
                    <TaskMeta>
                        <strong>Język:</strong> {language}
                    </TaskMeta>
                )}

                {description && (
                    <ContentSection>
                        <SectionTitle>Opis:</SectionTitle>
                        <SectionContent>{description}</SectionContent>
                    </ContentSection>
                )}

                {content && (
                    <ContentSection>
                        <SectionTitle>Treść:</SectionTitle>
                        <SectionContent>{content}</SectionContent>
                    </ContentSection>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Zamknij
                </Button>
            </Modal.Footer>
        </StyledModal>
    );
};

export default TaskContentModal;
