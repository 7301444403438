import React, { useState, useEffect } from 'react';
import { Modal, Table, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../hooks';
import axios from 'axios';
import config from '../../../config/config';

const CourseStatsModal = ({ show, handleClose, courseId, darkMode }) => {
    const { t } = useTranslation();
    const [studentsStats, setStudentsStats] = useState({});

    // Pobierz listę studentów dla kursu
    const {
        data: students,
        loading: studentsLoading,
        error: studentsError
    } = useFetch('TEACHER_COURSES_STUDENTS_GET', [courseId], show);

    // Pobierz statystyki dla każdego studenta
    useEffect(() => {
        const fetchStats = async () => {
            if (!students?.length) return;

            const stats = {};
            const enrolledStudents = students.filter(s => s.enrolled);

            for (const student of enrolledStudents) {
                try {
                    const response = await axios.get(
                        `${config.apiUrl}/teacher/stats/students/${student.id}/time`,
                        {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
                            }
                        }
                    );
                    stats[student.id] = response.data;
                } catch (error) {
                    console.error(`Error fetching stats for student ${student.id}:`, error);
                    stats[student.id] = { error: true };
                }
            }

            setStudentsStats(stats);
        };

        if (show && students?.length) {
            fetchStats();
        }
    }, [students, show]);

    const formatTime = (seconds) => {
        if (!seconds) return '-';
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${minutes}m`;
    };

    const getProgressColor = (completed, total) => {
        if (!total) return '';
        const percentage = (completed / total) * 100;
        if (percentage <= 33) return 'text-danger';
        if (percentage <= 66) return 'text-warning';
        return 'text-success';
    };

    const formatProgress = (completed, total) => {
        if (!total) return '-';
        const percentage = Math.round((completed / total) * 100);
        return `${completed}/${total} (${percentage}%)`;
    };

    if (studentsLoading) {
        return (
            <Modal show={show} onHide={handleClose} size="lg" className={darkMode ? 'modal-dark' : ''}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('courseStatistics')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center py-5">
                    <Spinner animation="border" />
                </Modal.Body>
            </Modal>
        );
    }

    if (studentsError) {
        return (
            <Modal show={show} onHide={handleClose} size="lg" className={darkMode ? 'modal-dark' : ''}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('courseStatistics')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="danger">{t('errorLoadingStudents')}</Alert>
                </Modal.Body>
            </Modal>
        );
    }

    const enrolledStudents = students?.filter(s => s.enrolled) || [];

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            className={darkMode ? 'modal-dark' : ''}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('courseStatistics')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {enrolledStudents.length === 0 ? (
                    <Alert variant="info">{t('noEnrolledStudents')}</Alert>
                ) : (
                    <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
                        <Table responsive bordered className={darkMode ? 'table-dark' : ''}>
                            <thead>
                            <tr>
                                <th>{t('student')}</th>
                                <th>{t('totalTime')}</th>
                                <th>{t('progress')}</th>
                                <th>{t('averageTimePerTask')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {enrolledStudents.map(student => {
                                const stats = studentsStats[student.id];
                                const isLoading = !stats;
                                const hasError = stats?.error;
                                const progressColor = !isLoading && !hasError ?
                                    getProgressColor(stats.summary.completed_tasks, stats.summary.total_available_tasks) : '';

                                return (
                                    <tr key={student.id}>
                                        <td>{`${student.name} ${student.surname}`}</td>
                                        {isLoading ? (
                                            <td colSpan={3} className="text-center">
                                                <Spinner animation="border" size="sm" />
                                            </td>
                                        ) : hasError ? (
                                            <td colSpan={3} className="text-center text-danger">
                                                {t('errorLoadingStats')}
                                            </td>
                                        ) : (
                                            <>
                                                <td>{formatTime(stats.summary.total_time_seconds)}</td>
                                                <td className={progressColor}>{formatProgress(stats.summary.completed_tasks, stats.summary.total_available_tasks)}</td>
                                                <td>{formatTime(Math.floor(stats.summary.total_time_seconds / (stats.summary.completed_tasks || 1)))}</td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CourseStatsModal;
