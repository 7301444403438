import React from 'react';

const ConsoleOutput = ({ consoleOutput, theme }) => {
    const renderTable = (tableData) => {
        if (!tableData || !tableData.headers || !tableData.rows) return null;

        return (
            <table style={{
                width: '100%',
                borderCollapse: 'collapse',
                fontFamily: 'Consolas, monospace',
                fontSize: '12px',
                marginTop: '4px'
            }}>
                <thead>
                <tr>
                    {tableData.headers.map((header, index) => (
                        <th key={index} style={{
                            backgroundColor: theme.console.table.headerBg,
                            color: theme.console.table.headerText,
                            padding: '4px 8px',
                            textAlign: 'left',
                            border: `1px solid ${theme.console.table.border}`,
                            transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease'
                        }}>
                            {header}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {tableData.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {tableData.headers.map((header, colIndex) => (
                            <td key={colIndex} style={{
                                padding: '4px 8px',
                                border: `1px solid ${theme.console.table.border}`,
                                backgroundColor: theme.console.table.cellBg,
                                color: theme.console.table.cellText,
                                transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease'
                            }}>
                                {typeof row[header] === 'object'
                                    ? JSON.stringify(row[header])
                                    : String(row[header] ?? '')}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    return (
        <div style={{
            flex: 1,
            width: 'auto',
            overflow: 'hidden',
            transition: 'all 0.3s ease, background-color 0.3s ease',
            backgroundColor: theme.console.background,
            borderLeft: `1px solid ${theme.console.border}`,
        }}>
            <div style={{
                padding: '10px',
                fontFamily: 'Consolas, monospace',
                fontSize: '12px',
                overflowY: 'auto',
                height: '100%',
                color: theme.console.text,
                transition: 'color 0.3s ease'
            }}>
                <div style={{
                    marginBottom: '8px',
                    color: theme.console.headerText,
                    borderBottom: `1px solid ${theme.console.border}`,
                    paddingBottom: '4px',
                    transition: 'color 0.3s ease, border-color 0.3s ease'
                }}>
                    Console
                </div>
                {consoleOutput.map((log, index) => (
                    <div
                        key={index}
                        style={{
                            padding: '4px 0',
                            borderBottom: `1px solid ${theme.console.border}`,
                            color: theme.console.logs[log.level],
                            fontFamily: 'Consolas, monospace',
                            transition: 'color 0.3s ease, border-color 0.3s ease'
                        }}
                    >
                        {log.isTable ? renderTable(log.tableData) : log.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ConsoleOutput;
